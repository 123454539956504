export const StackedList = ({ data, keys, userId, handleReadEvent }) => {
  if (!data || !keys?.length === 3) return <div>Loading...</div>
  return (
    <ul className="divide-y divide-gray-100">
      {data.map((entry) => {
        if (!entry || !entry.id || !entry.href)
          return <div key={JSON.stringify(entry)}></div>
        return (
          <a
            href={entry.href}
            onClick={(e) => {
              handleReadEvent(entry.id)
            }}
            key={entry.id + 'a'}
            className="block hover:bg-gray-50"
          >
            <li key={entry.id} className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {entry[keys[2]]}
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {entry[keys[1]]}
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {entry[keys[3]]}
                  </p>
                </div>
              </div>
              <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-gray-900">
                  {entry[keys[0]]}
                </p>
                <div className="mt-1 flex items-center gap-x-1.5">
                  {entry?.read ? (
                    <>
                      <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                        <div className="h-1.5 w-1.5 rounded-full bg-gray-200" />
                      </div>
                      <p className="text-xs leading-5 text-gray-500">Read</p>
                    </>
                  ) : (
                    <>
                      <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                        <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                      </div>
                      <p className="text-xs leading-5 text-gray-500">New</p>
                    </>
                  )}
                </div>
              </div>
            </li>
          </a>
        )
      })}
    </ul>
  )
}
