import Patients from './subcomponents/Patients'

const Home = () => {
  return (
    <>
      <Patients />
    </>
  )
}

export default Home
