import { axiosPrivate as axios } from './axios'

import { faker } from '@faker-js/faker'
faker.seed(337)

export const loadDemoUsers = () => {
  const users = []
  let randomNumberOfUsers = faker.helpers
    .shuffle(Array.from({ length: 100 }, (_, index) => index + 1))
    .pop() //Array sequence 0 - 100
  while (randomNumberOfUsers--) {
    const firstName = faker.person.firstName()
    const lastName = faker.person.lastName()
    users.push({
      id: faker.string.uuid(),
      name: faker.person.fullName({
        firstName,
        lastName
      }),
      email: faker.internet.email({
        firstName,
        lastName
      }),
      admin: faker.helpers.shuffle([true, false]).pop()
    })
  }
  return users
}

export const loadDemoOrganizations = () => {
  const schoolDescriptors = ['Public School', 'High School', 'Collegiate']
  const organizations = []
  let randomNumberOfOrgs = faker.helpers
    .shuffle(Array.from({ length: 5 }, (_, index) => index + 1))
    .pop() //Array sequence 0 - N
  while (randomNumberOfOrgs--) {
    organizations.push({
      id: faker.string.uuid(),
      name:
        faker.location.city() +
        ' ' +
        faker.helpers.shuffle(schoolDescriptors).at(-1)
    })
  }
  return organizations
}

export const fetchUser = (id) => {
  return axios.get(`/users/${id}`)
}
