import { useState } from 'react'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  AreaChart,
  Area,
  Cell,
  Legend,
  LabelList
} from 'recharts'

import { fetchPatients } from '../api/patients'
import { useQuery } from '@tanstack/react-query'
import { StatsCard } from './Stats'
import _ from 'lodash'
import { DateTime } from 'luxon'
import Spinner from './Spinner'

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#888888']

const Analytics = () => {
  const dateToday = new Date()
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ].slice(0, dateToday.getMonth() + 1)

  const prevYearMonthNames = ['September', 'October', 'November', 'December']

  const monthRange = [
    ...prevYearMonthNames.map((month) => {
      return month + ' ' + (dateToday.getFullYear() - 1)
    }),
    ...monthNames.map((month) => {
      return month + ' ' + dateToday.getFullYear()
    })
  ]

  const [kpis, setKpis] = useState([])
  const [cards, setCards] = useState([])

  const patientsQuery = useQuery({
    queryKey: ['patients'],
    queryFn: async function () {
      return await fetchPatients()
        .then((res) => {
          const select = res.data?.results || []
          generateKpis(monthRange, select)
          return select
        })
        .catch((err) => {
          console.log(err)
          return []
        })
    },
    retry: 3
  })

  const generateKpis = (months, patients) => {
    // console.log(patients)
    if (!months || !patients) return []
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    const kpisArray = [
      {
        name: 'Inbound Referrals',
        icon: 'UsersIcon',
        data: months.map((monthTuple) => {
          if (!monthTuple || typeof monthTuple !== 'string') return null
          const monthSplit = monthTuple.split(' ')
          const month = monthSplit[0]
          const year = monthSplit[1]
          if (!monthNames.includes(month)) return null
          return patients.filter((patient) => {
            const monthIndex = new Date(patient.referredDate).getMonth()
            const yearIndex = new Date(patient.referredDate).getFullYear()
            if (!monthIndex && monthIndex !== 0) return null
            return (
              month.toLowerCase() === monthNames[monthIndex].toLowerCase() &&
              year === yearIndex.toString()
            )
          }).length
        })
      },
      {
        name: 'Total Pending Report',
        icon: 'ClockIcon',
        data: months.map((monthTuple, idx) => {
          const month = monthTuple.split(' ')[0]
          const year = monthTuple.split(' ')[1].toString().toLowerCase()
          return patients.filter((patient) => {
            if (!monthTuple || typeof monthTuple !== 'string') return null
            //  console.log(...[month === monthNames[monthIndex], patient.status.label])
            const monthIndex = new Date(patient.referredDate).getMonth()
            const yearIndex = new Date(patient.referredDate).getFullYear()
            if (!monthIndex) return null
            return (
              month.toLowerCase() === monthNames[monthIndex].toLowerCase() &&
              year === yearIndex.toString() &&
              patient.status.label === 'Pending Report'
            )
          }).length
        })
      },
      {
        name: 'Students Cleared',
        icon: 'CheckCircleIcon',
        data: months.map((monthTuple, idx) => {
          const month = monthTuple.split(' ')[0]
          const year = monthTuple.split(' ')[1].toString().toLowerCase()
          return patients.filter((patient) => {
            const monthIndex = new Date(patient.referredDate).getMonth()
            const yearIndex = new Date(patient.referredDate).getFullYear()

            if (!monthTuple || typeof monthTuple !== 'string') return null
            if (!monthIndex) return null
            return (
              month.toLowerCase() === monthNames[monthIndex].toLowerCase() &&
              year === yearIndex.toString() &&
              patient.status.label === 'Cleared'
            )
          }).length
        })
      },
      {
        name: 'Students not Cleared',
        icon: 'XCircleIcon',
        data: months.map((monthTuple, idx) => {
          const month = monthTuple.split(' ')[0]
          const year = monthTuple.split(' ')[1]
          return patients.filter((patient) => {
            // console.log(patient)
            const monthIndex = new Date(patient.statusDate).getMonth()
            if (!monthIndex) return null
            return (
              month.toLowerCase() === monthNames[monthIndex].toLowerCase() &&
              year === new Date(patient.statusDate).getFullYear().toString() &&
              patient.status.label === 'Not Cleared'
            )
          }).length
        })
      }
    ]

    const cards = [
      {
        meta: {
          title: 'Hospitalizations Avoided',
          id: 'hospitalizationsAvoided',
          barChart: true,
          layout: {
            showXAxis: true
          }
        },
        data: months.map((monthTuple) => {
          return {
            name: monthTuple,
            value: patients.filter((patient) => {
              const monthIndex = new Date(patient.statusDate).getMonth()
              const yearIndex = new Date(patient.statusDate)
                .getFullYear()
                .toString()
              // console.log(monthIndex)
              // console.log(yearIndex)
              if (!monthIndex && monthIndex !== 0) return null
              const month = monthTuple.split(' ')[0]
              const year = monthTuple.split(' ')[1].toString()
              // console.log(month.toLowerCase() === monthNames[monthIndex].toLowerCase())
              // console.log(year.toLowerCase() === yearIndex.toString().toLowerCase())
              return (
                month.toLowerCase() === monthNames[monthIndex].toLowerCase() &&
                year === yearIndex &&
                patient.alternativeToInsiteCare.label
                  .toLowerCase()
                  .replace(/\s+/g, '') ===
                  'Hospital or Urgent Care'.toLowerCase().replace(/\s+/g, '')
              )
            }).length
          }
        })
      },
      {
        meta: {
          title: 'Ages of Patients Referred',
          id: 'agesOfPatientsReferred',
          areaChart: true,
          layout: {
            showXAxis: true
          }
        },
        data: months
          .map((monthTuple) => {
            const generateAgeGroup = (birthDate) => {
              const age =
                -1 *
                DateTime.fromJSDate(new Date(birthDate)).diffNow('years').years
              if (age <= 5) return '0 to 4'
              if (age > 7 && age <= 10) return '5 to 10'
              if (age > 10 && age <= 14) return '11 to 14'
              if (age > 14 && age < 19) return '15 to 18'
              else return 0
            }
            const split = monthTuple.split(' ')
            const month = split[0]
            const year = split[1].toString()
            return {
              month: monthTuple,
              value: _.groupBy(
                patients.filter((patient) => {
                  const monthIndex = new Date(patient.statusDate).getMonth()
                  const yearIndex = new Date(patient.statusDate).getFullYear()
                  if (!monthIndex && monthIndex !== 0) return null
                  return (
                    month.toLowerCase() ===
                      monthNames[monthIndex].toLowerCase() &&
                    year.toLowerCase() === yearIndex.toString().toLowerCase()
                  )
                }),
                (patient) => {
                  return generateAgeGroup(patient.birthDate)
                }
              )
            }
          })
          .map((datum) => {
            let sanitizedDatumValue = datum.value
            if (!sanitizedDatumValue['0 to 4'])
              sanitizedDatumValue['0 to 4'] = 0
            else
              sanitizedDatumValue['0 to 4'] =
                sanitizedDatumValue['0 to 4'].length
            if (!sanitizedDatumValue['5 to 10'])
              sanitizedDatumValue['5 to 10'] = 0
            else
              sanitizedDatumValue['5 to 10'] =
                sanitizedDatumValue['5 to 10'].length
            if (!sanitizedDatumValue['11 to 14'])
              sanitizedDatumValue['11 to 14'] = 0
            else
              sanitizedDatumValue['11 to 14'] =
                sanitizedDatumValue['11 to 14'].length
            if (!sanitizedDatumValue['15 to 18'])
              sanitizedDatumValue['15 to 18'] = 0
            else
              sanitizedDatumValue['15 to 18'] =
                sanitizedDatumValue['15 to 18'].length
            let draftObj = {
              ...{
                month: datum.month
              },
              ...sanitizedDatumValue
            }
            return draftObj
          })
      },
      {
        meta: {
          title: 'Referral Reason',
          id: 'referralReason',
          pieChart: true,
          layout: {
            showLabels: true
          }
        },
        data: _(
          patients
            .filter((patient) => patient.referralReason?.label)
            .map((patient) => patient.referralReason?.label)
        )
          .countBy((label) => {
            return label
          })
          .map((value, key) => {
            return {
              name: key,
              value: value
            }
          })
          .value()
      },
      {
        meta: {
          title: 'Alternatives to Care',
          id: 'alternativesToCare',
          pieChart: true,
          layout: {
            showLabels: true
          }
        },
        data: _(
          patients.map((patient) => patient.alternativeToInsiteCare.label)
        )
          .countBy((label) => {
            return label
          })
          .map((value, key) => {
            return {
              name: key,
              value: value
            }
          })
          .value()
      },
      {
        meta: {
          title: 'Gender Identity',
          id: 'genderIdentity',
          pieChart: true,
          layout: {
            showLabels: true
          }
        },
        data: _(patients.map((patient) => patient.gender))
          .countBy((label) => {
            return label
          })
          .map((value, key) => {
            return {
              name: key,
              value: value
            }
          })
          .value()
      }
    ]
    setCards(cards)
    setKpis(kpisArray)
  }

  const generateStats = (numberOfMonthsFromToday) => {
    if (!kpis || !cards) return []
    // console.log(kpis, cards)
    const statsArray = kpis.map((kpi) => {
      return {
        id: kpi.name + numberOfMonthsFromToday,
        name: kpi.name,
        icon: kpi.icon,
        stat: numberOfMonthsFromToday
          ? kpi.data
              .slice(-1 * (numberOfMonthsFromToday + 1))
              .reduce((a, b) => a + b)
          : kpi.data.reduce((a, b) => a + b)
      }
    })
    const cardsArray = cards.map((card) => {
      return {
        meta: card.meta,
        data: numberOfMonthsFromToday
          ? card.data.slice(-1 * (numberOfMonthsFromToday + 1))
          : card.data
      }
    })
    return {
      stats: statsArray,
      filteredCards: cardsArray
    }
  }

  const handleTabCLick = (e) => {
    setTabs(
      tabs.map((tab) => {
        if (e.target.id === tab.id) {
          tab.current = true
          generateStats(tab.numberOfMonths)
        } else tab.current = false
        return tab
      })
    )
  }

  const [tabs, setTabs] = useState([
    {
      id: '1month',
      name: '1 Month',
      onClick: handleTabCLick,
      current: false,
      numberOfMonths: 1
    },
    {
      id: '2months',
      name: '2 Months',
      onClick: handleTabCLick,
      current: false,
      numberOfMonths: 2
    },
    {
      id: '3months',
      name: '3 Months',
      onClick: handleTabCLick,
      current: false,
      numberOfMonths: 3
    },
    {
      id: 'allTime',
      name: 'All Time',
      onClick: handleTabCLick,
      current: true,
      numberOfMonths: null
    }
  ])
  const { stats, filteredCards } = generateStats(
    tabs.find((tab) => tab.current).numberOfMonths
  )
  const kpiConfig = {
    meta: {
      title: 'Top Reported Reasons for Care',
      chartComponent: InsiteBarChart,
      cardId: 'topReasonsForCare',
      description: ''
    },
    data: kpis
  }

  if (patientsQuery.isLoading)
    return (
      <div className="pt-64">
        <Spinner />
      </div>
    )
  if (patientsQuery.isError && patientsQuery.error?.response?.status === 403)
    return (
      <div>
        {patientsQuery.error.response.status} :Unauthorized. You shouldn't be
        seeing this and we've logged this error for investigation. Please try
        refreshing the page.
      </div>
    )
  if (patientsQuery.data?.length < 1)
    return (
      <>
        <div> Not enough entries...</div>
      </>
    )
  else
    return (
      <>
        <KpiList meta={kpiConfig.meta} data={kpiConfig.data} />
        <StatsCard stats={stats} tabs={tabs} />
        <ul className=" p-6 grid grid-cols-2 2xl:grid-cols-3 gap-6  ">
          {filteredCards.map((card) => (
            <Card key={card.meta.id} meta={card.meta} data={card.data} />
          ))}
        </ul>
      </>
    )
}

const Card = ({ data, meta }) => {
  return (
    <li
      key={meta.cardId}
      className=" col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
    >
      <div className="flex flex-col p-8">
        <h3 className="mt-1 text-2xl font-medium text-gray-900">
          {meta.title}
        </h3>
        <dl className="mt-1   place-content-center">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">{meta.description}</dd>
          <dt className="sr-only">Description</dt>
          <dd></dd>
        </dl>
      </div>
      <div className="-mt-px flex divide-x divide-gray-200">
        <div className="flex-auto w-0 place-content-center">
          {meta.barChart ? (
            <InsiteBarChart data={data} layout={meta.layout} />
          ) : (
            <></>
          )}
          {meta.areaChart ? (
            <InsiteAreaChart
              data={data}
              keys={['0 to 4', '5 to 10', '11 to 14', '15 to 18']}
            />
          ) : (
            <></>
          )}
          {meta.pieChart ? (
            <InsitePieChart data={data} keys={['value']} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </li>
  )
}

const renderCustomAxisTick = ({ x, y, stroke, value, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={10} textAnchor="end" transform="rotate(-35)">
        {payload.value}
      </text>
    </g>
  )
}
const InsiteBarChart = ({ data, keys, layout }) => {
  keys = keys || ['value']
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <BarChart data={data} layout="horizontal">
        {layout?.showXAxis ? (
          <XAxis
            height={75}
            dataKey="name"
            className=" font-extrabold"
            tick={renderCustomAxisTick}
          />
        ) : (
          <></>
        )}
        <YAxis />
        <Tooltip />
        {keys.map((key, idx) => {
          return (
            <Bar dataKey={key} key={key} fill={COLORS[idx % COLORS.length]}>
              {layout?.showLabels ? (
                <LabelList key={'label' + idx} dataKey="name" position="top" />
              ) : (
                <></>
              )}
              {data.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                )
              })}
            </Bar>
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}
const InsiteAreaChart = ({ data, keys, layout }) => {
  keys = keys || ['value']
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <AreaChart data={data}>
        <Legend iconSize="10" iconType="rect" verticalAlign="top" />
        <Tooltip />
        <YAxis />
        <XAxis
          dataKey="month"
          height={75}
          className=" font-extrabold"
          tick={renderCustomAxisTick}
        />
        {keys.map((key, idx) => {
          return (
            <Area
              key={key}
              type="monotone"
              dataKey={key}
              name={key}
              nameKey="month"
              stackId={1}
              stroke={COLORS[idx % COLORS.length]}
              fill={COLORS[idx % COLORS.length]}
            />
          )
        })}
      </AreaChart>
    </ResponsiveContainer>
  )
}

const InsitePieChart = ({ data, keys }) => {
  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <PieChart>
        {keys.map((key, idx) => {
          return (
            <Pie
              key={key + idx}
              data={data}
              dataKey={key}
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius="80%"
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          )
        })}
        <Legend />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}

const KpiList = ({ meta, data }) => {
  const dateToday = new Date()
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ].slice(0, dateToday.getMonth() + 1)

  const prevYearMonthNames = ['September', 'October', 'November', 'December']

  const monthRange = [
    ...prevYearMonthNames.map((month) => {
      return month + ' ' + (dateToday.getFullYear() - 1)
    }),
    ...monthNames.map((month) => {
      return month + ' ' + dateToday.getFullYear()
    })
  ]

  return (
    <div className="px-4 py-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Key Performance Indicators
          </h1>
          <p className="mt-2 text-sm text-gray-700">{}</p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th></th>
                  {monthRange.map((month) => (
                    <th
                      key={month}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                      {month}{' '}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {data.map((item) => (
                  <tr key={item.name} className="even:bg-gray-50">
                    <td>{item.name}</td>
                    {monthRange.map((month, idx) => (
                      <td
                        key={month}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
                      >
                        {item.data[idx]}{' '}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Analytics
