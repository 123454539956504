import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
// import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from 'react-auth-kit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { refreshApi } from './hooks/useRefreshToken'
const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        authType={'localstorage'}
        authName={'_auth'}
        cookieDomain={window.location.hostname || 'insitehealth.net'}
        cookieSecure={window.location.protocol === 'https:'}
        refresh={refreshApi}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
