import { useNavigate } from 'react-router-dom'
import { useSignOut, useIsAuthenticated } from 'react-auth-kit'
import { useEffect } from 'react'

const Logout = () => {
  const navigate = useNavigate()
  const signout = useSignOut()
  const authenticated = useIsAuthenticated()
  useEffect(() => {
    navigate('/login')
    setTimeout(() => {
      if (authenticated) signout()
      return <></>
    }, 100)
  }, [navigate, signout, authenticated])
  return <></>
}

export default Logout
