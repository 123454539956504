import { faker } from '@faker-js/faker'
import { usePatientStore } from './store'
import { axiosPrivate } from '../api/axios'

faker.seed(337)

export const loadDemoPatients = () => {
  const existingPatients = usePatientStore.getState().patients
  if (existingPatients.length) return existingPatients

  const peopleInit = [...Array(50)].map((entry) => {
    const schoolDescriptors = ['Public School', 'High School', 'Collegiate']

    const statusDescriptors = usePatientStore.getState().statusLabels
    const insurers = usePatientStore.getState().insurers

    const reasonDescriptors = [
      { label: 'Depression' },
      { label: 'Anxiety' },
      { label: 'PTSD' },
      { label: 'ADHD' },
      { label: 'Long-term Illness' },
      { label: 'Bullying' },
      { label: 'Violence' },
      { label: 'Social Media' },
      { label: 'Other' }
    ]

    const alternativeToInsiteCareDescriptors = [
      'Hospital or Urgent Care',
      'No licensed behavioural healthcare rendered',
      'Other non-urgent provider'
    ]

    let personObject = {
      id: faker.string.uuid()
    }

    personObject.status = faker.helpers.shuffle(statusDescriptors).at(-1)
    personObject.insurer = faker.helpers.shuffle(insurers).at(-1)
    personObject.referredDate = faker.date.recent({ days: 180 })
    if (personObject.status.label === 'Scheduled') {
      personObject.statusDate = faker.date.soon({ refDate: new Date() })
    }
    if (
      personObject.status.label === 'Pending Evaluation' ||
      personObject.status.label === 'Clearance Sent'
    ) {
      personObject.statusDate = faker.date.between({
        from: personObject.referredDate,
        to: new Date()
      })
    } else personObject.statusDate = personObject.referredDate
    personObject.school =
      faker.location.city() +
      ' ' +
      faker.helpers.shuffle(schoolDescriptors).at(-1)
    personObject.biologicalSex = faker.helpers
      .shuffle([
        { id: 0, label: 'male' },
        { id: 1, label: 'female' }
      ])
      .pop()
    personObject.gender = faker.person.gender()
    personObject.firstName = faker.person.firstName({
      sex: personObject.gender
    })
    personObject.lastName = faker.person.lastName()
    personObject.name = personObject.firstName + ' ' + personObject.lastName
    personObject.referredBy = faker.person.fullName()
    personObject.provider = faker.person.fullName()
    personObject.email = faker.internet.email({
      firstName: personObject.firstName,
      lastName: personObject.lastName
    })
    personObject.milestones = [personObject.status]

    personObject.reasonForCare = faker.helpers.shuffle(reasonDescriptors).pop()
    personObject.reasonForCare.alternativeToInsiteCare = faker.helpers
      .shuffle(alternativeToInsiteCareDescriptors)
      .pop()
    personObject.numFiles = faker.helpers.shuffle([...Array(5).keys()]).at(-1)
    personObject.files = new Array(personObject.numFiles + 1)
      .fill(1)
      .map((file) => {
        const fileName = faker.system.fileName()
        const path = faker.internet.url()

        return { fileName, path }
      })
    personObject.events = [
      ...Array(
        //empt array of random length using faker helpers so that seed can generate consistent results
        faker.helpers.shuffle([...Array(5).keys()]).pop()
      )
    ]

    personObject.events = personObject.events.map((item) => {
      item = {
        id: faker.string.uuid(),
        patientId: personObject.id,
        patient: personObject.name,
        meetingWith: faker.person.fullName(),
        startDateTime: faker.date.soon({ days: 60 }),
        durationMinutes: faker.helpers.shuffle([30, 60]).pop()
      }
      return item
    })

    personObject.activity = [
      {
        id: faker.string.uuid(),
        type: 'Referred',
        dateTime: personObject.referredDate,
        person: { name: personObject.referredBy },
        comment: 'Created referral'
      }
    ]

    personObject = {
      ...{
        birthDate: faker.date.birthdate({
          mode: 'age',
          min: 8,
          max: 18
        }),
        notes: {
          admin: faker.lorem.paragraphs(),
          clinical: faker.lorem.paragraphs()
        }
      },
      ...personObject
    }
    return personObject
  })
  usePatientStore.getState().setPatients(peopleInit)
  return peopleInit
}

export async function fetchPatients() {
  const response = await axiosPrivate.get('/patients', {}, {})
  return response
}

export async function fetchPatient(id) {
  const response = await axiosPrivate.get(`/patients/${id}`)
  return response
}

export async function updatePatient(patient) {
  const response = await axiosPrivate.patch(
    `/patients/${patient.id}`,
    patient,
    {}
  )
  return response
}

export async function createPatient(patient) {
  const response = await axiosPrivate.post(`/patients`, patient)
  return response
}

export async function deletePatient(id) {
  const response = await axiosPrivate.delete(`/patients/${id}`)
  return response
}

export async function uploadFile(file, id) {
  const response = await axiosPrivate.post(`/patients/file/${id}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('_auth')}`
    }
  })
  return response
}

export async function deleteFile(path, patientId) {
  const response = await axiosPrivate.delete(
    `/patients/${patientId}/file/${path}`
  )
  return response
}

export async function getFile(path, patientId) {
  if (!path || !patientId) return new Error('Invalid path or patientId')
  const response = await axiosPrivate.get(
    `/patients/${patientId}/file/${path}`,
    {
      responseType: 'blob'
    }
  )
  return response
}
