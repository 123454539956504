import { Outlet } from 'react-router-dom'
import { useIsAuthenticated } from 'react-auth-kit'
import Navbar from './subcomponents/Navbar'

const Layout = () => {
  const isAuthenticated = useIsAuthenticated()
  return (
    <main className="App">
      {isAuthenticated() ? <Navbar /> : null}
      <Outlet />
    </main>
  )
}

export default Layout
