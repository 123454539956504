import { Fragment, useState, useEffect, useMemo } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/20/solid'
import { useLocation /*useNavigate*/ } from 'react-router-dom'
import { useAuthUser } from 'react-auth-kit'
import InitialsAvatar from 'react-initials-avatar'
import { useQuery } from '@tanstack/react-query'
import { fetchUser } from '../../api/users'

const userNavigation = [
  { name: 'Settings', href: '/settings', show: ['admin'] },
  { name: 'Sign out', href: '/logout', show: ['user', 'userAdmin', 'admin'] }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Navbar() {
  const auth = useAuthUser()
  const user = {
    email: auth()?.email || '',
    role: auth()?.role || null,
    name: auth()?.name || ''
  }

  if (window.pendo?.initialize) {
    window.pendo.initialize({
      visitor: {
        id: auth()?.id,
        email: auth()?.email,
        role: auth()?.role,
        full_name: auth()?.name
      },
      account: {
        id: auth()?.managingOrgId,
        org: auth()?.orgId
      }
    })
  }

  const { data, isLoading } = useQuery({
    queryKey: ['userFetch'],
    queryFn: async function () {
      return fetchUser(auth().id)
    },
    select: (response) => response?.data?.notifications,
    onError: (error) => {
      console.error(error)
    }
  })

  const [isAdmin, setIsAdmin] = useState({ checked: false, current: false })
  const [sentNotifcations, setSentNotifications] = useState([])
  const [pageReady, setPageReady] = useState(false)
  let notifcationsToSend
  if (!isAdmin.current && !isAdmin.checked && user.role) {
    // console.log('setting isadmin')
    setIsAdmin({
      checked: true,
      current: ['admin', 'userAdmin'].includes(user.role)
    })
  }
  // setIsAdmin({current: (auth().role === 'admin')})
  const location = useLocation()
  let from = location?.pathname

  const navigation = [
    {
      name: 'Dashboard',
      href: '/',
      current: false,
      show: ['user', 'userAdmin', 'admin']
    },
    {
      name: 'Analytics',
      href: '/analytics',
      current: false,
      show: ['userAdmin', 'admin']
    },
    { name: 'Calendar', href: '/calendar', current: false, show: ['admin'] }
  ]
    .map((item) => {
      item.current = item.href === from
      return item
    })
    .filter((item) => item.show.includes(user.role))

  const initNotifcations = async () => {
    const notifcations = await localStorage.getItem('sentNotifcations')
    if (notifcations) setSentNotifications(JSON.parse(notifcations))
    return setPageReady(true)
  }

  useEffect(() => {
    initNotifcations()
  }, [])

  useEffect(() => {
    localStorage.setItem('sentNotifcations', JSON.stringify(sentNotifcations))
  }, [sentNotifcations])

  useEffect(() => {
    const notifcationItem = notifcationsToSend.pop()
    if (notifcationItem) {
      setSentNotifications([...sentNotifcations, notifcationItem.id])
      const notification = new Notification(
        notifcationItem.title,
        notifcationItem
      )
      notification.onclick = (e) => {
        e.preventDefault()
        window.open(window.location.origin + '/notifications', '_blank')
        notification.close()
      }
    }
  }, [notifcationsToSend, sentNotifcations])

  notifcationsToSend = useMemo(() => {
    if (
      pageReady &&
      data &&
      auth()?.notificationPermissions === 'granted' &&
      auth()?.role === 'admin'
    )
      return data
        .map((notification) => {
          const icon = '/InSite-Favicon.svg'
          const body = notification.message || 'A new notification has arrived!'
          const title = JSON.stringify(
            notification?.title || 'InSite Notification'
          )
          if (notification.read) return null
          if (!notification?.id) return null
          if (
            sentNotifcations?.length &&
            sentNotifcations.includes(notification?.id)
          )
            return null
          if (title && body && icon && notification?.id)
            return { id: notification?.id, title, body, icon }
          return null
        })
        .filter((notification) => notification !== null)
    return []
  }, [auth, data, pageReady, sentNotifcations])

  // if (isLoading) return <div>Loading...</div>
  return (
    <>
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <div className="-ml-2 mr-2 flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src="/InSite-Favicon.svg"
                      alt="InSite Health"
                    />
                  </div>
                  <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <a href="/createreferral">
                      "
                      <button
                        type="button"
                        className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        <PlusIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        Create Referral
                      </button>
                    </a>
                  </div>
                  <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center space-x-3">
                    {isAdmin.current ? (
                      <>
                        <a
                          href="/support"
                          className="invisible rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="sr-only">Support</span>
                          <QuestionMarkCircleIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </a>
                        <a
                          href="/notifications"
                          type="button"
                          className={
                            ' flex rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                          }
                        >
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6 " aria-hidden="true" />
                          <span
                            className={
                              'text-white font-bold  bg-red-500 hover:bg-white hover:text-black flex-shrink align-top text-xs p-1 ring-red-500 ring-1 rounded-full ' +
                              (!data ? ' invisible' : '')
                            }
                          >
                            {data?.filter((entry) => !entry.read).length || 0}
                          </span>
                        </a>
                      </>
                    ) : (
                      ''
                    )}

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div className="flex-grow">
                        <Menu.Button className="flex rounded-full bg-gray-100 text-sm p-1 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open user menu</span>
                          <InitialsAvatar
                            name={user?.name || 'I H '}
                            className="place-items-center"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => {
                            return item?.show?.includes(user.role) ? (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ) : (
                              ''
                            )
                          })}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5 sm:px-6">
                  <div className="flex-shrink-0">
                    <InitialsAvatar
                      name={user?.name || 'I H'}
                      alt="Profile Pic"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium text-gray-400">
                      {user.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2 sm:px-3">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}
