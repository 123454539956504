import { ArrowsUpDownIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import InitialsAvatar from 'react-initials-avatar'
import { useQuery } from '@tanstack/react-query'
import { fetchPatients } from '../../api/patients'
import { Fragment } from 'react'
import Spinner from '../Spinner'
export default function Pateints() {
  const [nameSortAtoZ, setNameSortAtoZ] = useState(0)
  const [schoolSortAtoZ, setSchoolSortAtoZ] = useState(0)
  const [patients, setPatients] = useState([])
  const [sortedPatients, setSortedPatients] = useState([...patients])

  const patientsQuery = useQuery({
    queryKey: ['patients'],
    retry: 3,
    queryFn: async function () {
      return await fetchPatients()
        .then((res) => {
          const patients = res.data?.results || []
          setPatients(patients)
          if (!sortedPatients.length) setSortedPatients(patients)
          return res
        })
        .catch((err) => {
          return err
        })
    },
    select: (data) => data.data?.results
  })

  const handleAtoZClick = (e) => {
    let sortDirection = 1
    if (e?.preventDefault) e.preventDefault()
    if (nameSortAtoZ === 1) {
      sortDirection = -1
    }
    setNameSortAtoZ(sortDirection)
    const draft = [
      ...patients.sort((personA, personB) => {
        return personA.firstName.toUpperCase() > personB.firstName.toUpperCase()
          ? sortDirection
          : -1 * sortDirection
      })
    ]
    setSortedPatients(draft)
  }

  const handleSchoolAtoZClick = (e) => {
    let sortDirection = 1
    if (e?.preventDefault) e.preventDefault()
    if (schoolSortAtoZ === 1) {
      setSchoolSortAtoZ(-1)
      sortDirection = -1
    } else setSchoolSortAtoZ(1)
    const draft = [
      ...patients.sort((personA, personB) => {
        return personA.org?.name?.toUpperCase() >
          personB.org?.name?.toUpperCase()
          ? sortDirection
          : -1 * sortDirection
      })
    ]
    setSortedPatients(draft)
  }

  // if (patientsQuery.isLoading) return <div>Loading patients dashboard...</div>
  if (patientsQuery.isLoading)
    return (
      <div className="pt-64">
        <Spinner />
      </div>
    )
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-10">
      <div className="sm:flex sm:items-center container">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Referred Patients
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the patients you have referred.
          </p>
        </div>
      </div>

      <div className="  flex-auto flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full  divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="flex py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    <span> Name </span>
                    <button onClick={handleAtoZClick}>
                      <ArrowsUpDownIcon className="h-4" />{' '}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    <span> School </span>
                    <button onClick={handleSchoolAtoZClick}>
                      <ArrowsUpDownIcon className="h-4" />{' '}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="text-right relative py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">View</span>
                  </th>
                </tr>
                <tr>
                  <th>
                    <div className="">
                      <div className=" inset-10" aria-hidden="true">
                        <div className="w-full border-t border-gray-100" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="">
                      <div className=" inset-10" aria-hidden="true">
                        <div className="w-full border-t border-gray-100" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="">
                      <div className=" inset-10" aria-hidden="true">
                        <div className="w-full border-t border-gray-100" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="">
                      <div className=" inset-10" aria-hidden="true">
                        <div className="w-full border-t border-gray-100" />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {sortedPatients?.map &&
                  sortedPatients?.map((person) => {
                    if (
                      !['status', 'name', 'firstName', 'lastName', 'org'].every(
                        (value) => Object.keys(person).includes(value)
                      )
                    )
                      return (
                        <Fragment
                          key={person.id || person._id || person}
                        ></Fragment>
                      )
                    return (
                      <tr key={person.id || person._id || person}>
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center">
                            <div className="h-11 w-11 flex-shrink-0">
                              <InitialsAvatar
                                name={person.name}
                                className="rounded-full ring-gray-200 ring-1 pl-3"
                              />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {person.name}
                              </div>
                              <div className="mt-1 text-gray-500">
                                {person.email?.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {person.org?.name}
                          </div>
                          <div className="mt-1 text-gray-500">
                            Referred by: {person?.referredBy?.name}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <span
                            className={(person.status?.statusColor === 'green'
                              ? 'bg-green-50'
                              : 'bg-red-50'
                            ).concat(
                              ' inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'
                            )}
                          >
                            {person.status?.label}
                          </span>
                        </td>
                        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <a
                            href={'/detail/' + person.id}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            View
                            <span className="sr-only">, {person.name}</span>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
