import Form from './subcomponents/Form'

const CreateReferral = () => {
  return (
    <>
      <Form />
    </>
  )
}

export default CreateReferral
