import { create } from 'zustand'
// import { persist, devtools } from 'zustand/middleware'
import { DateTime } from 'luxon'
import _ from 'lodash'

const compareEvents = ({ startDateTime }, { daySelected, month, year }) => {
  const startDateTimeAsDateObject = new Date(startDateTime)
  if (
    !startDateTime ||
    !(typeof startDateTimeAsDateObject?.getMonth === 'function') ||
    !month ||
    !year
  )
    return false

  if (!daySelected)
    return (
      startDateTimeAsDateObject.getMonth() === month &&
      startDateTimeAsDateObject.getFullYear() === year
    )
  else
    return (
      startDateTimeAsDateObject.getMonth() === month &&
      startDateTimeAsDateObject.getFullYear() === year &&
      startDateTimeAsDateObject.getDate() === daySelected
    )
  //console.log('not found')
}

export const patientStore = (set, get) => ({
  patientNotifications: [],
  patients: [],
  kpis: [],
  cards: [],
  events: [],
  stats: [],
  filteredCards: [],
  filteredEvents: [],
  patientFullNames: [],
  allowedDurations: [
    { id: 0, name: '30 Minutes' },
    { id: 1, name: '60 Minutes' }
  ],
  payers: [
    { id: 0, label: 'BCBS-Anthem' },
    { id: 1, label: 'BCBS-IL (HMO)' },
    { id: 2, label: 'BCBS-NJ' },
    { id: 3, label: 'BCBS-NJ (PPO)' },
    { id: 4, label: 'BCBS-NJ - FEP' },
    { id: 5, label: 'BCBS-NJ - NJ Direct (PPO)' },
    { id: 6, label: 'Borough of Elmer-Police Dept' },
    { id: 7, label: 'Christian Brothers Services' },
    { id: 8, label: 'Cigna' },
    { id: 9, label: 'Cigna (PPO)' },
    { id: 10, label: 'GEHA' },
    { id: 11, label: 'GWH-Cigna - Cigna' },
    { id: 12, label: 'Horizon NJ Health' },
    { id: 13, label: 'Humana Medicare Advantage Plan' },
    { id: 14, label: 'Self Pay' },
    { id: 15, label: 'Tricare' },
    { id: 16, label: 'Trustmark' },
    { id: 17, label: 'UHC-Community Plan' },
    { id: 18, label: 'United Healthcare/Optum' },
    { id: 19, label: 'United of Omaha' },
    { id: 20, label: 'Wellcare Health Plans of New Jersey' },
    { id: 21, label: 'Medicaid' },
    { id: 22, label: 'Something else' }
  ],
  patient: {},
  statusLabels: [
    { id: 0, label: 'Referred', statusColor: 'green' },
    { id: 1, label: 'Scheduled', statusColor: 'green' },
    { id: 2, label: 'Canceled', statusColor: 'red' },
    { id: 3, label: 'Pending Report', statusColor: 'green' },
    { id: 4, label: 'Cleared', statusColor: 'green' },
    { id: 5, label: 'Not Cleared', statusColor: 'red' },
    { id: 6, label: 'IEP Completed', statusColor: 'green' },
    { id: 7, label: 'IEP Not Completed', statusColor: 'red' },
    { id: 8, label: 'School Support Needed', statusColor: 'red' }
  ],

  providers: [
    { id: 0, name: 'Alfina Evans, MSN, APN, PMHNP-BC' },
    { id: 1, name: 'Aaron Z Spector, MSN, APN, PMHNP-BC' },
    { id: 2, name: 'Corine Iwuala' },
    { id: 3, name: 'Diane Burt, PMHNP' },
    { id: 4, name: 'Ernst Renondeau, PMHNP' },
    { id: 5, name: 'Joshua Schorr, MSN, APN, PMHNP-BC' },
    { id: 6, name: 'Lisa Ewan, PMHNP' },
    { id: 7, name: 'Michelle Guarnizo, APN, PMHNP-BC' },
    { id: 8, name: 'Matthew Minteer, MSN, APN, PMHNP-BC' },
    { id: 9, name: 'Mary Pajic, LCSW' },
    { id: 10, name: 'Steven Padula, MSN, APN, PMHNP-BC' },
    { id: 11, name: 'Tomeka Mapp, MSN, APN PMHNP-BC' }
  ],
  numberOfStudents: 0,
  setPatients: (patients) => {
    return set((state) => {
      const events = patients.map((patient) => patient.events).flat()
      const patientFullNames = patients
        .map((patient, idx) => {
          return { name: patient.name, id: idx }
        })
        .filter(Boolean)
      const patientNotifications = patients
        .filter((patient) => {
          return patient?.status?.label?.toLowerCase() === 'referred'
        })
        .map((patient) => {
          return {
            id: _.uniqueId('notification'),
            patientId: patient.id,
            patient: patient.name,
            href: `/detail/${patient.id}`,
            title: 'New Referral',
            message: `${patient.name} has been referred to Insite Care by ${patient.referrer}.  Please schedule an appointment.`,
            notificationDate: patient.referredDate,
            readBy: []
          }
        })
      return {
        patientNotifications,
        events,
        patients,
        patientFullNames
      }
    }, false)
  },
  getFilteredEvents: ({ daySelected, month, year }) => {
    return set((state) => {
      if (!month || !year) return { filteredEvents: [] }

      const filteredEvents = state.events.filter((event) => {
        // console.log(event, { daySelected, month, year })
        return compareEvents(event, { daySelected, month, year })
      })
      // console.log(filteredEvents)
      return { filteredEvents }
    }, false)
  },
  generateStats: (numberOfMonthsFromToday) => {
    return set((state) => {
      const statsArray = state.kpis.map((kpi) => {
        return {
          id: kpi.name + numberOfMonthsFromToday,
          name: kpi.name,
          icon: kpi.icon,
          stat: kpi.data
            .slice(-1 * numberOfMonthsFromToday)
            .reduce((a, b) => a + b)
        }
      })
      const cardsArray = state.cards.map((card) => {
        return {
          meta: card.meta,
          data: card.data.slice(-1 * numberOfMonthsFromToday)
        }
      })
      return {
        stats: statsArray,
        filteredCards: cardsArray
      }
    }, false)
  },
  generateKpis: (months) => {
    return set((state) => {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      const kpisArray = [
        {
          name: 'Inbound Referrals',
          icon: 'UsersIcon',
          data: months.map((month) => {
            return state.patients.filter((patient) => {
              const monthIndex = new Date(patient.referredDate).getMonth()
              return (
                month.toLowerCase() === monthNames[monthIndex].toLowerCase()
              )
            }).length
          })
        },
        {
          name: 'Total Pending Report',
          icon: 'ClockIcon',
          data: months.map((month, idx) => {
            return state.patients.filter((patient) => {
              const monthIndex = new Date(patient.statusDate).getMonth()
              //   console.log(...[month === monthNames[monthIndex], patient.status.label])
              return (
                month.toLowerCase() === monthNames[monthIndex].toLowerCase() &&
                patient.status.label === 'Pending Report'
              )
            }).length
          })
        },
        {
          name: 'Students Cleared',
          icon: 'CheckCircleIcon',
          data: months.map((month, idx) => {
            return state.patients.filter((patient) => {
              const monthIndex = new Date(patient.statusDate).getMonth()
              return (
                month.toLowerCase() === monthNames[monthIndex].toLowerCase() &&
                patient.status.label === 'Cleared'
              )
            }).length
          })
        },
        {
          name: 'Students not Cleared',
          icon: 'XCircleIcon',
          data: months.map((month, idx) => {
            return state.patients.filter((patient) => {
              const monthIndex = new Date(patient.statusDate).getMonth()
              return (
                month.toLowerCase() === monthNames[monthIndex].toLowerCase() &&
                patient.status.label === 'Not Cleared'
              )
            }).length
          })
        }
      ]

      const cards = [
        {
          meta: {
            title: 'Hospitalizations Avoided',
            id: 'hospitalizationsAvoided',
            barChart: true,
            layout: {
              showXAxis: true
            }
          },
          data: months.map((month) => {
            return {
              name: month,
              value: state.patients.filter((patient) => {
                const monthIndex = new Date(patient.statusDate).getMonth()
                return (
                  month.toLowerCase() ===
                    monthNames[monthIndex].toLowerCase() &&
                  patient.reasonForCare.alternativeToInsiteCare
                    .toLowerCase()
                    .replace(/\s+/g, '') ===
                    'Hospital or Urgent Care'.toLowerCase().replace(/\s+/g, '')
                )
              }).length
            }
          })
        },
        {
          meta: {
            title: 'Ages of Patients Referred',
            id: 'agesOfPatientsReferred',
            areaChart: true,
            layout: {
              showXAxis: true
            }
          },
          data: months
            .map((month) => {
              const generateAgeGroup = (birthDate) => {
                const age =
                  -1 *
                  DateTime.fromJSDate(new Date(birthDate)).diffNow('years')
                    .years
                if (age > 7 && age <= 10) return '5 to 10'
                if (age > 10 && age <= 14) return '11 to 14'
                if (age > 14 && age < 19) return '15 to 18'
                else return 0
              }
              return {
                month: month,
                value: _.groupBy(
                  state.patients.filter((patient) => {
                    const monthIndex = new Date(patient.statusDate).getMonth()

                    return (
                      month.toLowerCase() ===
                      monthNames[monthIndex].toLowerCase()
                    )
                  }),
                  (patient) => {
                    return generateAgeGroup(patient.birthDate)
                  }
                )
              }
            })
            .map((datum) => {
              let sanitizedDatumValue = datum.value
              if (!sanitizedDatumValue['5 to 10'])
                sanitizedDatumValue['5 to 10'] = 0
              else
                sanitizedDatumValue['5 to 10'] =
                  sanitizedDatumValue['5 to 10'].length
              if (!sanitizedDatumValue['11 to 14'])
                sanitizedDatumValue['11 to 14'] = 0
              else
                sanitizedDatumValue['11 to 14'] =
                  sanitizedDatumValue['11 to 14'].length
              if (!sanitizedDatumValue['15 to 18'])
                sanitizedDatumValue['15 to 18'] = 0
              else
                sanitizedDatumValue['15 to 18'] =
                  sanitizedDatumValue['15 to 18'].length
              let draftObj = {
                ...{
                  month: datum.month
                },
                ...sanitizedDatumValue
              }
              return draftObj
            })
        },
        {
          meta: {
            title: 'Reasons for Care',
            id: 'reasonsForCare',
            pieChart: true,
            layout: {
              showLabels: true
            }
          },
          data: _(state.patients.map((patient) => patient.reasonForCare.label))
            .countBy((label) => {
              return label
            })
            .map((value, key) => {
              return {
                name: key,
                value: value
              }
            })
            .value()
        },
        {
          meta: {
            title: 'Alternatives to Care',
            id: 'alternativesToCare',
            pieChart: true,
            layout: {
              showLabels: true
            }
          },
          data: _(
            state.patients.map(
              (patient) => patient.reasonForCare.alternativeToInsiteCare
            )
          )
            .countBy((label) => {
              return label
            })
            .map((value, key) => {
              return {
                name: key,
                value: value
              }
            })
            .value()
        },
        {
          meta: {
            title: 'Gender Identity',
            id: 'genderIdentity',
            pieChart: true,
            layout: {
              showLabels: true
            }
          },
          data: _(state.patients.map((patient) => patient.gender))
            .countBy((label) => {
              return label
            })
            .map((value, key) => {
              return {
                name: key,
                value: value
              }
            })
            .value()
        }
      ]
      // console.dir(cards)
      return {
        numberOfStudents: state.patients.length,
        kpis: kpisArray,
        cards
      }
    }, false)
  }
})

export const usePatientStore = create(patientStore)
