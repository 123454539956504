import { axiosPrivate as axios } from '../api/axios'
import { createRefresh } from 'react-auth-kit'

export const refreshApiConfig = {
  interval: 5, // Refreshs the token in every 5 minutes
  refreshApiCallback: async ({
    authToken,
    // authTokenExpireAt,
    refreshToken
    // refreshTokenExpiresAt,
    // authUserState
  }) => {
    // API container function
    try {
      console.log('refreshing')
      const response = await axios.post(
        '/auth/refresh-tokens',
        { refreshToken: refreshToken },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true
        }
      )
      const accessToken = response?.data?.access?.token
      const accessTokenExpirey = response?.data?.access?.expires
      const accessTokenExpireyInMinutes =
        (new Date(accessTokenExpirey) - new Date()) / 60000
      const newRefreshToken = response?.data?.refresh?.token
      const newRefreshTokenExpirey = response?.data?.refresh?.expires
      const newRefreshExpireyInMinutes =
        (new Date(newRefreshTokenExpirey) - new Date()) / 60000
      console.log('refreshed', response.data)
      return {
        isSuccess: true,
        newAuthToken: accessToken,
        newAuthTokenExpireIn: accessTokenExpireyInMinutes,
        newRefreshToken: newRefreshToken,
        newRefreshTokenExpiresIn: newRefreshExpireyInMinutes
      }
    } catch (error) {
      console.error(error)
      return {
        isSuccess: false
      }
    }
  }
}

export const refreshApi = createRefresh(refreshApiConfig)
export default refreshApi
