import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Login from './Login'
import { axiosPrivate as axios } from '../api/axios'
import ErrorAlert from './subcomponents/Alert'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const NAME_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
const REGISTER_URL = '/auth/register'

const Register = () => {
  const emailRef = useRef()
  const errRef = useRef()
  const nameRef = useRef()

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [name, setName] = useState('')
  const [validName, setValidName] = useState(true)
  const [nameFocus, setNameFocus] = useState(false)

  const [pwd, setPwd] = useState('')
  const [validPwd, setValidPwd] = useState(false)
  const [pwdFocus, setPwdFocus] = useState(false)

  const [matchPwd, setMatchPwd] = useState('')
  const [validMatch, setValidMatch] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    emailRef.current.focus()
  }, [])

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email) || email === '')
  }, [email])

  useEffect(() => {
    setValidName(NAME_REGEX.test(name) || name === '')
  }, [name])

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd) || pwd === '')
    setValidMatch(pwd === matchPwd)
  }, [pwd, matchPwd])

  useEffect(() => {
    // setErrMsg(errMsg);
    errRef.current?.focus()
  }, [errMsg])

  const handleSubmit = async (e) => {
    e.preventDefault()
    // if button enabled with JS hack
    const v1 = EMAIL_REGEX.test(email)
    const v2 = PWD_REGEX.test(pwd)
    const v3 = NAME_REGEX.test(name)
    setIsLoading(true)
    if (!v1 || !v2 || !v3) {
      setErrMsg('Invalid Entry')
      return
    }
    try {
      //console.log('registering:', REGISTER_URL)
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({ email: email, password: pwd, name: name }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      )
      // TODO: remove console.logs before deployment
      //console.log(JSON.stringify(response?.data));
      //console.log(JSON.stringify(response))
      if (response) setIsLoading(false)
      setSuccess(true)
      //clear state and controlled inputs
      setEmail('')
      setPwd('')
      setMatchPwd('')
    } catch (err) {
      //   console.err(err.response)
      const status = err?.response?.status
      const message = `${err?.response?.statusText || 'Error'}:  ${
        err?.response?.data?.message ||
        'An unknown error occurred. Please try again.'
      }`
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (status) {
        setErrMsg(message)
      } else {
        setErrMsg('Unknown Error')
      }
    }
  }

  return (
    <>
      {success ? (
        <Login statusAlert="Success: Please sign in with your new account." />
      ) : (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src="/InSite-Favicon.svg"
              alt="InSite Health"
            />
            <h2 className="signin">Register for an InSite Account</h2>
          </div>
          <div className="sm:mx-auto sm:w-full sm:max-w-sm pt-5">
            <ErrorAlert message={errMsg} />
          </div>

          {/* <p ref={errRef} className={errMsg ? "errmsg" : "invisible"} aria-live="assertive">{errMsg}</p> */}
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="signin">
                  Email Address:
                </label>
                <input
                  type="text"
                  id="email"
                  ref={emailRef}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  aria-invalid={validEmail ? 'false' : 'true'}
                  aria-describedby="uidnote"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                  className={validEmail ? 'signin' : 'signinError'}
                />
              </div>
              <div className="flex items-center justify-between space-x-2">
                <label htmlFor="name" className="signin">
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  ref={nameRef}
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                  aria-invalid={validName ? 'false' : 'true'}
                  aria-describedby="uidnote"
                  onFocus={() => setNameFocus(true)}
                  onBlur={() => setNameFocus(false)}
                  className={
                    validName || validName === '' ? 'signin' : 'signinError'
                  }
                />
              </div>
              <div className="flex items-center justify-between space-x-2 ">
                <label htmlFor="password" className="signin">
                  Password:
                </label>
                <input
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                  aria-invalid={validPwd ? 'false' : 'true'}
                  aria-describedby="pwdnote"
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                  className={validPwd ? 'signin' : 'signinError'}
                />
              </div>

              <div className="flex items-center justify-between space-x-2 ">
                <label htmlFor="confirm_pwd" className="signin">
                  Confirm Password:
                </label>
                <input
                  type="password"
                  autoComplete="new-password"
                  id="confirm_pwd"
                  onChange={(e) => setMatchPwd(e.target.value)}
                  value={matchPwd}
                  required
                  aria-invalid={validMatch ? 'false' : 'true'}
                  aria-describedby="confirmnote"
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                  className={validMatch ? 'signin' : 'signinError'}
                />
              </div>
              <div className="text-sm ">
                <div
                  id="pwdnote"
                  className={pwdFocus && !validPwd ? 'signin' : 'invisible'}
                >
                  <div className="flex">
                    <InformationCircleIcon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                    <div className=" px-2">
                      {} 8 to 24 characters.
                      <br />
                      Must include a number and a special character.
                      <br />
                      Allowed special characters:{' '}
                      <span aria-label="exclamation mark">!</span>{' '}
                      <span aria-label="at symbol">@</span>{' '}
                      <span aria-label="hashtag">#</span>{' '}
                      <span aria-label="dollar sign">$</span>{' '}
                      <span aria-label="percent">%</span>
                    </div>
                  </div>
                </div>

                <div
                  id="confirmnote"
                  className={matchFocus && !validMatch ? 'signin' : 'invisible'}
                >
                  <div className="flex">
                    <InformationCircleIcon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                    <div className=" px-2">
                      {} Must match the first password input field.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  disabled={
                    !validName || !validPwd || !validMatch || isLoading
                      ? true
                      : false
                  }
                  className={
                    !validName || !validPwd || !validMatch || isLoading
                      ? ' signin-disabled'
                      : 'signin'
                  }
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
          <p className="mt-10 text-center text-sm text-gray-400">
            Already registered? {}
            <a href="/login" className="signin">
              Login
            </a>
          </p>
        </div>
      )}
    </>
  )
}

export default Register
