import axios from 'axios'
import { createRefresh } from 'react-auth-kit'
import { refreshApiConfig } from '../hooks/useRefreshToken'
import Login from '../components/Login'

const INSITE_API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? window.env.REACT_APP_INSITE_API_BASE_URL
    : process.env.REACT_APP_INSITE_API_BASE_URL ||
      'https://api.insitehealth.net/v1'

export const axiosPublic = axios.create({
  baseURL: INSITE_API_BASE_URL
})

// console.log(localStorage.getItem('_auth'))

export default axios

export const axiosPrivate = axios.create({
  baseURL: INSITE_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('_auth')}`,
    'Content-Type': 'application/json'
  }
  // withCredentials: true
})

axiosPrivate.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      return <Login />
    }
    throw error
  }
)

axiosPrivate.interceptors.request.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      return await createRefresh(refreshApiConfig) // Call the refresh API
    }
    throw error
  }
)
