import { StackedList } from './StackedList'
import { useQuery } from '@tanstack/react-query'
import { fetchUser } from '../api/users'
import { useAuthUser } from 'react-auth-kit'
import { axiosPrivate as axios } from '../api/axios'

export const NotificationsPage = () => {
  const authUser = useAuthUser()
  const userId = authUser().id

  const { data } = useQuery({
    queryKey: ['userFetch'],
    queryFn: async function () {
      return fetchUser(userId).catch(console.error)
    },
    select: (response) => response?.data?.notifications
    // onSuccess: (notifications) => {
    //     console.log(notifications)
    // },
  })

  const handleReadEvent = async (id) => {
    // console.log(id)
    return (
      axios
        .patch(`/users/${userId}/notifications/${id}`)
        // .then((response) => {
        //     console.log(response)
        // })
        .catch((error) => {
          console.log(error)
        })
    )
  }

  return (
    <>
      <main className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        <StackedList
          data={data}
          keys={['notificationDate', 'person', 'title', 'message']}
          linkKey={['href']}
          handleReadEvent={handleReadEvent}
        />
      </main>
    </>
  )
}
