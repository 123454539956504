import { Fragment, useState, useMemo, useCallback, useEffect } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Menu, Transition, Listbox, Combobox } from '@headlessui/react'
import {
  ChevronUpDownIcon,
  EllipsisVerticalIcon,
  CheckIcon,
  UsersIcon
} from '@heroicons/react/24/outline'
import queryString from 'query-string'
import InitialsAvatar from 'react-initials-avatar'
import {
  providers as getProviders,
  allowedDurations as getAllowedDurations
} from '../api/statics'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchPatients, updatePatient } from '../api/patients'
import { useAuthUser } from 'react-auth-kit'
import { Notification } from './Notification'
import { Modal } from './Modal'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const formatDate = (date) => {
  if (!date) return null
  const dateToFormat = new Date(date)
  return (
    dateToFormat.getFullYear() +
    '-' +
    ('0' + (dateToFormat.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + dateToFormat.getDate()).slice(-2)
  )
}

const compareEvents = ({ startDateTime }, { daySelected, month, year }) => {
  const startDateTimeAsDateObject = new Date(startDateTime)

  if (
    !startDateTime ||
    !(typeof startDateTimeAsDateObject?.getMonth === 'function') ||
    !month ||
    !year
  )
    return false

  if (!daySelected)
    return (
      startDateTimeAsDateObject.getMonth() === month &&
      startDateTimeAsDateObject.getFullYear() === year
    )
  else
    return (
      startDateTimeAsDateObject.getMonth() === month &&
      startDateTimeAsDateObject.getFullYear() === year &&
      startDateTimeAsDateObject.getDate() === daySelected
    )
  //console.log('not found')
}

export default function Calendar() {
  let date = new Date()

  const user = useAuthUser()
  const [isAdmin, setIsAdmin] = useState(user().role === 'admin')
  if (!isAdmin) setIsAdmin(user().role === 'admin')

  const [daySelected, setDaySelected] = useState(false)
  const [month, setMonth] = useState(date.getMonth())
  const [year, setYear] = useState(date.getFullYear())
  const [dateRightNow] = useState(date)
  const [editMeetingId, setEditMeetingId] = useState()
  const [providers, setProviders] = useState([])
  const [allowedDurations, setAllowedDurations] = useState([])
  const [draftPatient, setDraftPatient] = useState()
  const [filteredEvents, setFilteredEvents] = useState([])
  const [notification, setNotification] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [events, setEvents] = useState([])
  const [patients, setPatients] = useState([])

  if (!providers?.length) getProviders.then(setProviders)
  if (!allowedDurations?.length)
    getAllowedDurations.then((res) => {
      setAllowedDurations(res)
    })

  const patientQuery = useQuery({
    queryKey: ['patients'],
    queryFn: async function () {
      return await fetchPatients()
        .then((res) => {
          setPatients(res.data?.results || [])
          return res.data?.results || []
        })
        .catch((err) => {
          return setNotification({
            type: 'error',
            heading: 'Error fetching patients',
            message: `There was an error fetching patients. ${err}`
          })
        })
    }
  })

  const patientEventMutation = useMutation({
    mutationFn: async function (event) {
      return await updatePatient(event)
        .then(() => {
          setNotification({
            type: 'success',
            heading: 'Event changed',
            message: 'The event has been changed or added'
          })
          return patientQuery.refetch()
        })
        .catch((err) => {
          return setNotification({
            type: 'error',
            heading: 'Event not saved',
            message: `There was an error saving the event. ${err}`
          })
        })
    }
  })

  useEffect(() => {
    if (!patients) return setEvents([])
    const eventsDraft = [...patients]
      ?.map((patient) => patient.events)
      .filter((events) => events.length)
      .flat()
    if (patients && eventsDraft?.length) return setEvents(eventsDraft)
    if (patients && !eventsDraft?.length) return setEvents([])
  }, [patients])

  useEffect(() => {
    if (!month || !year || !events) return setFilteredEvents([])
    const draftFilteredEvents = events.filter((event) => {
      return compareEvents(event, { daySelected, month, year })
    })
    return setFilteredEvents(draftFilteredEvents)
  }, [events, month, year, daySelected])

  const patientFullNames = useMemo(
    () =>
      patients?.map((patient) => ({
        id: patient.id,
        name: patient.name
      })),
    [patients]
  )

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const [selectProvider, setSelectProvider] = useState(providers[0] || {})
  const [selectDuration, setSelectDuration] = useState(
    allowedDurations[0] || {}
  )
  const [selectStartDateTime, setSelectStartDateTime] = useState(dateRightNow)

  const [draftEventObject, setDraftEventObject] = useState({
    id: null,
    patientId: null,
    meetingWith: null,
    startDateTime: null,
    duration: null
  })

  if (!selectProvider?.name && providers?.length)
    setSelectProvider(providers[0])
  if (!selectDuration?.label && allowedDurations?.length)
    setSelectDuration(allowedDurations[0])

  const generateDaysOfCalendarMonth = useCallback(
    ({ year, month }) => {
      // Get the first day of the month
      const firstDate = new Date(year, month, 1).getDate()
      const lastDate = new Date(year, month + 1, 0).getDate()
      const lastWeekDayofPreviousMonth = new Date(year, month, 0).getDate()

      let dayBuilder = []
      let day = firstDate
      const builtDate = new Date(year, month, day)
      const firstWeekDayOfMonth = builtDate.getDay()
      let prevMonthDate = lastWeekDayofPreviousMonth - (firstWeekDayOfMonth - 1)
      while (day <= lastDate) {
        while (prevMonthDate <= lastWeekDayofPreviousMonth) {
          const prevMonthBuiltDate = new Date(year, month - 1, prevMonthDate)
          dayBuilder.push({
            date: prevMonthBuiltDate
          })
          prevMonthDate += 1
        }
        const currentMonthBuiltDate = new Date(year, month, day)
        const isCurrentMonth =
          month === dateRightNow.getMonth() &&
          year === dateRightNow.getFullYear()
        const isSelected = daySelected === currentMonthBuiltDate.getDate()
        // console.log(isSelected, daySelected, currentMonthBuiltDate.getDate())
        const isToday =
          dateRightNow.getMonth() === currentMonthBuiltDate.getMonth() &&
          dateRightNow.getFullYear() === currentMonthBuiltDate.getFullYear() &&
          dateRightNow.getDate() === currentMonthBuiltDate.getDate()
        dayBuilder.push({
          date: currentMonthBuiltDate,
          isCurrentMonth,
          isToday,
          isSelected
        })
        day++
      }
      return dayBuilder
    },
    [dateRightNow, daySelected]
  )

  const days = useState(generateDaysOfCalendarMonth({ year, month }))[0]

  // useEffect(
  //   oldDays => {
  //     setDays(generateDaysOfCalendarMonth({ year, month }))
  //     // getFilteredEvents({ month, year, daySelected })
  //     // console.dir(filteredEvents)

  //   },
  //   [year, month, generateDaysOfCalendarMonth, daySelected]
  // )

  const handleCalendarEditSave = (e) => {
    e.preventDefault()
    const event = events.find((event) => event._id === editMeetingId)
    const patient = patients.find((patient) => patient.id === event.patientId)
    const draftPartialPatient = {
      id: patient.id,
      updateEvent: {
        id: event._id,
        meetingWith: selectProvider,
        startDateTime: selectStartDateTime,
        duration: selectDuration
      }
    }
    delete draftPartialPatient._id
    delete draftPartialPatient.updateEvent._id
    patientEventMutation
      .mutateAsync(draftPartialPatient)
      .then(() => {
        setNotification({
          type: 'success',
          heading: 'Event changed',
          message: 'The event has been changed or added'
        })
        setEditMeetingId(false)
        return patientQuery.refetch()
      })
      .catch((err) => {
        setNotification({
          type: 'error',
          heading: 'Event not saved',
          message: `There was an error saving the event. ${err}`
        })
      })
  }

  const handleDateChange = (e) => {
    if (!e?.target?.value) return null
    setSelectStartDateTime(formatDate(e.target.value))
  }

  const handleNewCalendarEvent = async (e) => {
    e.preventDefault()
    const newEvent = {
      patientId: draftPatient?.id || null,
      meetingWith: selectProvider,
      startDateTime: new Date(e.target.startDateTime.value),
      duration: selectDuration,
      patientName: draftPatient?.patientName || null
    }
    setDraftEventObject(newEvent)
    if (draftPatient) {
      draftPatient.newEvent = newEvent
      delete draftPatient.patientName
      await patientEventMutation
        .mutateAsync(draftPatient)
        .then(() => {
          setNotification({
            type: 'success',
            heading: 'Event changed',
            message: 'The event has been changed or added'
          })
          setSelectStartDateTime() &&
            setSelectDuration() &&
            setSelectProvider() &&
            setDraftPatient()
          return patientQuery.refetch()
        })
        .catch((err) => {
          setNotification({
            type: 'error',
            heading: 'Event not saved',
            message: `There was an error saving the event. ${err}`
          })
        })
    }
  }

  const handleDayClick = (e) => {
    e.preventDefault()
    setDaySelected(Number(e?.target?.innerText || dateRightNow.getDate()))
  }

  const handleChangeMeetingWith = (e) => {
    const draftEvent = {
      ...draftEventObject,
      meetingWith: e?.target?.innerText
    }
    setDraftEventObject(draftEvent)
  }

  const handlePatientNameChange = (e) => {
    const patient = patients.find((patient) => patient.id === e?.id)
    if (patient)
      setDraftPatient({
        id: patient.id,
        patientName: patient.name
      })
  }

  const handleCalendarEdit = (e) => {
    e.preventDefault()
    const query = queryString.parseUrl(e.target.href)?.query
    setEditMeetingId(query.id)
    const event = events.find((event) => event._id === query.id)
    if (!event) {
      setNotification({
        type: 'error',
        heading: 'Event not found',
        message: `There was an error finding the event.`
      })
      return null
    }
    setDraftEventObject(event)
  }

  const handleCalendarCancel = (e) => {
    e.preventDefault()
    const query = queryString.parseUrl(e.target.href)?.query
    setEditMeetingId(query.id)
    const event = events.find((event) => event.id === query.id)
    if (!event) {
      setNotification({
        type: 'error',
        heading: 'Event not found',
        message: `There was an error finding the event.`
      })
      return null
    }
    const patient = patients.find((patient) => patient.id === event.patientId)
    const draftPartialPatient = {
      id: patient.id,
      updateEvent: {
        id: event._id,
        cancelMeeting: true
      }
    }
    setShowModal({
      heading: 'Delete Event',
      message:
        'Are you sure you want to delete this event?  This cannot be undone.',
      dismiss: () => setShowModal(false),
      confirm: () =>
        patientEventMutation
          .mutateAsync(draftPartialPatient)
          .then(() => {
            return setNotification({
              type: 'success',
              heading: 'Event deleted',
              message: 'The event has been deleted'
            })
          })
          .catch((err) => {
            return setNotification({
              type: 'error',
              heading: 'Event not deleted',
              message: `There was an error deleting the event. ${err}`
            })
          })
    })
    //    const draftEvent = {...draftEventObject, id: query.id}
  }

  const resetMonth = () => {
    setDaySelected(false)
  }
  const prevMonth = (e) => {
    if (month === 0) {
      setMonth(11)
      setYear(year - 1)
    } else setMonth(month - 1)
    setDaySelected(false)
    return month
  }

  const nextMonth = (e) => {
    if (month === 11) {
      setMonth(0)
      setYear(year + 1)
    } else setMonth(month + 1)
    setDaySelected(false)
    return month
  }

  return (
    <>
      <div className="mx-auto max-w-7xl lg:px-8 pt-10 md:grid h-screen md:grid-cols-2 md:divide-x md:divide-gray-200">
        <section className="">
          <div className=" flex items-center">
            <h2 className="flex-auto px-4 text-sm font-semibold text-gray-900">
              {months[month] + ' ' + year}{' '}
            </h2>
            <button
              type="button"
              className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={prevMonth}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={nextMonth}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>
          <div className="mt-2 grid grid-cols-7 text-sm">
            {days.map((day, dayIdx) => (
              <div
                key={day.date}
                className={classNames(
                  dayIdx > 6 && 'border-t border-gray-200',
                  'py-2'
                )}
              >
                <button
                  onClick={handleDayClick}
                  type="button"
                  className={classNames(
                    day.isSelected && 'text-white',
                    !day.isSelected && day.isToday && 'text-indigo-600',
                    !day.isSelected &&
                      !day.isToday &&
                      day.isCurrentMonth &&
                      'text-gray-900',
                    !day.isSelected &&
                      !day.isToday &&
                      !day.isCurrentMonth &&
                      'text-gray-400',
                    day.isSelected && day.isToday && 'bg-indigo-600',
                    day.isSelected && !day.isToday && 'bg-gray-900',
                    !day.isSelected && 'hover:bg-gray-200',
                    (day.isSelected || day.isToday) && 'font-semibold',
                    'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                  )}
                >
                  <time dateTime={day.date}>
                    {new Date(day.date).getDate()}
                  </time>
                </button>
              </div>
            ))}
          </div>
          <div
            className={`mt-10 mx-3 flex rounded-md ring-8 ring-gray-100 ${
              isAdmin ? '' : ' invisible'
            }`}
          >
            <form
              className="p-4 flex-grow columns-1 divide-black-100 divide-y "
              onSubmit={handleNewCalendarEvent}
            >
              <div className="p-2 flex w-full justify-between align-middle">
                <h3 className="text-lg">Create a New Event</h3>
              </div>
              <div className="w-full p-2 justify-between flex">
                <p className="text-md">Provider</p>
                <CalendarListBox
                  data={providers}
                  selected={selectProvider}
                  setSelected={setSelectProvider}
                ></CalendarListBox>
              </div>

              <div className="w-full p-2 justify-between flex">
                <label htmlFor="startDateTime" className="text-md">
                  Meeting Date
                </label>
                <input
                  onChange={handleDateChange}
                  className="relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 text-sm p-2 ml-4 w-1/2 "
                  type="date"
                  id="startDateTime"
                  name="trip-start"
                  placeholder={
                    formatDate(selectStartDateTime) || formatDate(dateRightNow)
                  }
                />
              </div>

              <div className="w-full p-2">
                <p className="text-md">Duration</p>
                <CalendarListBox
                  data={allowedDurations}
                  selected={selectDuration}
                  setSelected={setSelectDuration}
                ></CalendarListBox>
              </div>

              <div className="w-full pt-2 place-content-end">
                <p className="text-md">Patient</p>
                <div className="p-2">
                  <CalendarCommandPalette
                    data={patientFullNames}
                    handler={handlePatientNameChange}
                  />
                </div>
              </div>
              <div className="sr-only">New Event</div>
              <button
                disabled={
                  !selectProvider ||
                  !selectDuration ||
                  !draftPatient ||
                  !selectStartDateTime
                }
                className={`${!selectProvider || !selectDuration || !draftPatient || !selectStartDateTime ? 'signin-disabled' : 'signin'} `}
                type="submit"
              >
                Create Event
              </button>
            </form>
          </div>
        </section>
        <section className="mt-12 md:mt-0 md:pl-14">
          <h3 className="text-md">
            {'Events scheduled for ' +
              (daySelected ? daySelected + '/' : '') +
              (month + 1) +
              '/' +
              year}
          </h3>
          {daySelected ? (
            <button
              onClick={resetMonth}
              className="hover:underline mt-1 flex flex-grow text-sm italic  rounded-md"
            >
              {/* <CalendarIcon className='flex-shrink p-1 align-top'></CalendarIcon> */}
              <span className="flex-auto underline align-top font-semibold">
                {'View  '}
              </span>
              <span className="flex-auto hover:underline align-top font-semibold">
                &#160;
                {'month instead?'}
              </span>
            </button>
          ) : (
            ''
          )}
          <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
            {!filteredEvents?.length ? <h4>No events for this period.</h4> : ''}
            {!filteredEvents ||
              filteredEvents?.map((meeting) => (
                <form
                  key={meeting.id || meeting._id}
                  className="flex-auto"
                  onSubmit={handleCalendarEditSave}
                >
                  <li
                    key={meeting.id || meeting._id}
                    className="group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100"
                  >
                    <InitialsAvatar
                      name={meeting?.patientName || 'InSite Patient'}
                      className="h-10 w-10 flex-none rounded-full"
                    />
                    <div className="flex-auto">
                      <div className="text-gray-900">
                        {(meeting?.patientName || 'InSite Patient') +
                          ' is meeting with '}
                        {editMeetingId === meeting._id ? (
                          <CalendarListBox
                            data={providers}
                            selected={selectProvider}
                            setSelected={setSelectProvider}
                            onKeyUp={handleChangeMeetingWith}
                          />
                        ) : (
                          <span>
                            {' '}
                            {meeting?.meetingWith?.name || ' an unkown person'}
                          </span>
                        )}
                      </div>

                      {editMeetingId === meeting._id ? (
                        <>
                          <label htmlFor="startDateTime" className="flex">
                            New date:
                          </label>
                          <input
                            onChange={handleDateChange}
                            className=" rounded-md ring-slate-100 text-sm"
                            type="date"
                            id="startDateTime"
                            name="trip-start"
                            value={
                              formatDate(selectStartDateTime) ||
                              formatDate(meeting.startDateTime)
                            }
                          />{' '}
                        </>
                      ) : (
                        <p className="mt-0.5">
                          <time dateTime={formatDate(meeting.startDateTime)}>
                            {formatDate(meeting.startDateTime)}
                          </time>
                        </p>
                      )}
                      {editMeetingId === meeting._id ? (
                        <>
                          <CalendarListBox
                            data={allowedDurations}
                            selected={selectDuration}
                            setSelected={setSelectDuration}
                          ></CalendarListBox>
                          <div className="flex-none">
                            <button className="mt-2 signin" type="submit">
                              Save Changes
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault()
                                setSelectStartDateTime()
                                setSelectDuration()
                                setSelectProvider()
                                setEditMeetingId(false)
                              }}
                              type="reset"
                            >
                              Cancel Changes
                            </button>
                          </div>
                        </>
                      ) : (
                        <p>
                          {meeting.duration?.label
                            ? meeting.duration.label
                            : ''}
                        </p>
                      )}
                    </div>
                    <Menu
                      as="div"
                      className="relative opacity-50 focus-within:opacity-100 group-hover:opacity-100"
                    >
                      <div>
                        <Menu.Button className="-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href={
                                    '/calendar/edit?' +
                                    queryString.stringify({
                                      patientId: meeting.patientId,
                                      id: meeting.id || meeting._id
                                    })
                                  }
                                  onClick={(e) => {
                                    setSelectProvider(meeting.meetingWith)
                                    setSelectDuration(meeting.duration)
                                    setSelectStartDateTime(
                                      meeting.startDateTime
                                    )
                                    handleCalendarEdit(e)
                                  }}
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Edit
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href={
                                    '/calendar/cancel?' +
                                    queryString.stringify({
                                      patientId: meeting.patientId,
                                      id: meeting.id
                                    })
                                  }
                                  onClick={handleCalendarCancel}
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Cancel Meeting
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </li>
                </form>
              ))}
          </ol>
        </section>
      </div>
      {showModal ? (
        <Modal
          dismiss={showModal.dismiss}
          heading={showModal.heading}
          message={showModal.message}
          confirm={showModal.confirm}
        ></Modal>
      ) : (
        ''
      )}
      {notification ? (
        <Notification
          type={notification.type}
          heading={notification.heading}
          message={notification.message}
          handler={setNotification}
        />
      ) : (
        <></>
      )}
    </>
  )
}

const CalendarListBox = ({ data, selected, setSelected }) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">
                {selected?.name || selected?.label}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {data.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {person.name || person.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

const CalendarCommandPalette = ({ data, handler }) => {
  const [query, setQuery] = useState('')
  // const [open, setOpen] = useState(true)
  const [selected, setSelected] = useState({ id: '', name: '' })
  if (!data || !data.length) return <></>
  const filteredPeople =
    query === ''
      ? []
      : data.filter((person) => {
          return person?.name?.toLowerCase().includes(query.toLowerCase())
        })
  return (
    <Combobox
      as="div"
      onChange={(event) => {
        handler(event)
        setSelected(event)
      }}
    >
      <Combobox.Input
        value={selected.name}
        className="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm"
        placeholder="Search patients..."
        onChange={(event) => {
          setSelected({ id: '', name: event.target.value })
          setQuery(event.target.value)
        }}
      />

      {filteredPeople.length > 0 && (
        <Combobox.Options
          static={false}
          className="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
        >
          {filteredPeople.map((person) => (
            <Combobox.Option
              key={person.id}
              value={person}
              className={({ active }) =>
                classNames(
                  'cursor-default select-none rounded-md px-4 py-2',
                  active && 'bg-indigo-600 text-white'
                )
              }
            >
              {person.name}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}

      {query !== '' && filteredPeople.length === 0 && (
        <div className="px-4 py-14 text-center sm:px-14">
          <UsersIcon
            className="mx-auto h-6 w-6 text-gray-400"
            aria-hidden="true"
          />
          <p className="mt-4 text-sm text-gray-900">
            No people found using that search term.
          </p>
        </div>
      )}
    </Combobox>
  )
}
