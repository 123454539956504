import React from 'react'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid'

export const ErrorAlert = (props) => {
  const message = props?.message || null
  if (message?.length) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800"> {message} </h3>
          </div>
        </div>
      </div>
    )
  } else return <div />
}

export const StatusAlert = (props) => {
  const message = props?.message || null
  if (message?.length) {
    return (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800"> {message} </h3>
          </div>
        </div>
      </div>
    )
  } else return <div />
}
export default ErrorAlert
