import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Switch } from '@headlessui/react'
import { useAuthUser, useAuthHeader } from 'react-auth-kit'
import { Notification } from './Notification'
import { Modal } from './Modal'
import { InsiteListBox } from './InsiteListBox'
import _ from 'lodash'
// import { loadDemoOrganizations } from '../api/users'
import InitialsAvatar from 'react-initials-avatar'

import ContentEditable from 'react-contenteditable'

import { useQuery, useMutation } from '@tanstack/react-query'

import { axiosPrivate as axios } from '../api/axios'

import {
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon
} from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SettingsPage = () => {
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)

  // const [allowSuborgUsersToSeePeerReferrals,
  //   setAllowSuborgUsersToSeePeerReferrals
  // ] = useState(true)

  const [draftUser, setDraftUser] = useState({})
  const [draftOrg, setDraftOrg] = useState({})
  const [statusMsg, setStatusMsg] = useState(false)
  const [showStatusColorChange, setShowStatusColorChange] = useState(false)
  const [twoFactorAuthenticationEnabled, setTtwoFactorAuthenticationEnabled] =
    useState(false)
  const [autoApproveReferralsEnabled, setAutoApproveReferralsEnabled] =
    useState(true)
  const [allowSuborgUsersToSetupSuborgs, setAllowSuborgUsersToSetupSuborgs] =
    useState(true)
  const [showModal, setShowModal] = useState(false)
  const [newUserEdit, setNewUserEdit] = useState(false)
  const [passwordVerified, setPasswordVerified] = useState(false)

  const userName = useRef()
  const userPassword = useRef()
  const userConfirmPassword = useRef()
  const userCurrentPassword = useRef()
  //  const userEmail = useRef()
  const userPhoneNumber = useRef()

  const NAME_REGEX = /^[a-z ,.'-]+$/i
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
  //const EMAIL_REGEX = /^[\w-..]+@([\w-]+\.)+[\w-]{2,4}$/
  const PHONE_REGEX = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/

  const initialUserSettings = {
    name: {
      edit: false,
      new: '',
      old: '',
      type: 'text',
      autoFill: 'name',
      ref: userName
    },
    password: {
      edit: false,
      confirm: userConfirmPassword,
      new: '',
      old: '',
      type: 'password',
      autoFill: 'password',
      maskValues: true,
      ref: userPassword,
      requireCurrentPassword: true
    },
    phoneNumber: {
      edit: false,
      new: '',
      old: '',
      type: 'tel',
      autoFill: 'tel',
      ref: userPhoneNumber
    },
    automaticTimezoneEnabled: {
      edit: false,
      new: '',
      old: '',
      ref: automaticTimezoneEnabled
    },
    twoFactorAuthenticationEnabled: {
      edit: false,
      new: '',
      old: '',
      ref: twoFactorAuthenticationEnabled
    }
  }
  const [userSettingsDraft, setUserSettingsDraft] =
    useState(initialUserSettings)

  const auth = useAuthUser()
  const authHeader = useAuthHeader()

  const params = useParams()
  const page = params?.page?.toLowerCase() || 'general'

  const getUsers = async () => {
    const response = await axios.get('/users', {
      headers: {
        Authorization: authHeader(),
        'Content-Type': 'application/json'
      },

      withCredentials: true
    })
    return response?.data || []
  }

  const getOrgs = async () => {
    const response = await axios.get(
      '/orgs',
      {},
      {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0'
        }
      }
    )
    // console.log(response)
    return response.data || []
    // return await { results: loadDemoOrganizations() }
  }

  const deleteOrg = async ({ id }) => {
    const response = await axios.delete(`/orgs/${id}`)
    // console.log(response)
    return response?.data || []
  }

  const deleteUser = async ({ id }) => {
    const response = await axios.delete(`/users/${id}`)
    // console.log(response)
    return response?.data || []
  }
  const patchOrg = async (body) => {
    if (!body.id && !body.name) return []

    const response = await (body.id
      ? axios.patch(`/orgs/${body.id}`, body)
      : axios.post(`/orgs`, body))
    // console.log(response)
    return response?.data || []
  }

  const sendInviteEmail = async ({ from, orgId, email }) => {
    const response = await axios.post(`/auth/send-invite-email`, {
      from,
      orgId,
      email
    })
    return response || []
  }
  const patchUser = async (body) => {
    const email = body.email
    const id = body.id
    delete body.id
    const response = await (id
      ? axios.patch(`/users/${id}`, body)
      : axios.post(`/users`, { email }))
    // console.log(response)
    return response?.data || []
  }
  const userQuery = useQuery({
    queryKey: ['users'],
    queryFn: ['userAdmin', 'admin'].includes(auth()?.role) ? getUsers : () => []
  })

  const orgQuery = useQuery({
    queryKey: ['organizations'],
    queryFn: ['userAdmin', 'admin'].includes(auth()?.role) ? getOrgs : () => []
  })

  const userUpdate = useMutation({
    mutationKey: ['updateUsers'],
    mutationFn: patchUser,
    onSuccess: (e) => {
      const heading = `${e?.email || 'User'} updated`
      if (e?.email)
        setStatusMsg({
          heading,
          message: 'User has been updated',
          type: 'success'
        })
      return userQuery.refetch()
    }
  })

  const checkNewUserInviteEmailandSendInviteEmail = useMutation({
    mutationKey: ['inviteUser'],
    mutationFn: sendInviteEmail,
    onSuccess: (data, variables) => {
      const heading = `${variables?.email || 'User'} sent invite email`
      setStatusMsg({
        heading,
        message: `Email has been sent to ${variables.email}.  Once they accept the invite, they will be able to log in and create referrals for your organization.`,
        type: 'success'
      })
      //return userQuery.refetch()
    },
    onError: (error, variables) => {
      const errorStatusCode = error?.response?.status
      let message
      if (errorStatusCode === 409) {
        message = `The email ${variables.email} is already in use or has a pending invite.  Please try again with a different email.`
      }
      if (errorStatusCode === 500) {
        message = `The server encountered a problem sending the email.  We've logged this event for investigation.  Please try again later.`
      }
      if (errorStatusCode === 400) {
        message = `The email ${variables.email} is not valid.  Please try again with a different and valid email.`
      }
      const heading = `${variables?.email || 'User'} invite email failed`
      setStatusMsg({
        heading,
        message,
        type: 'error'
      })
      //return userQuery.refetch()
    }
  })

  const userDelete = useMutation({
    mutationKey: ['deleteUser'],
    mutationFn: deleteUser,
    onSuccess: (e) => {
      const heading = `${e?.email || 'User'} deleted`
      if (e?.email)
        setStatusMsg({
          heading,
          message:
            'User has been removed.  Note that all their referrals remain.',
          type: 'success'
        })
      return userQuery.refetch()
    }
  })

  const orgDelete = useMutation({
    mutationKey: ['deleteOrg'],
    mutationFn: deleteOrg,
    onSuccess: (e) => {
      const heading = `${e?.name || 'Organization'} deleted`
      if (e?.name)
        setStatusMsg({
          heading,
          message:
            'Organization has been deleted.  Please note that all referrals remain.',
          type: 'success'
        })
      return orgQuery.refetch()
    }
  })

  const orgUpdate = useMutation({
    mutationKey: ['updateOrgs'],
    mutationFn: patchOrg,
    onSuccess: (e) => {
      const heading = `${e?.name + ' updated' || 'Organization created'}`
      if (e?.name)
        setStatusMsg({
          heading,
          message: 'Organization has been updated',
          type: 'success'
        })
      return orgQuery.refetch()
    }
  })

  const managingOrg = orgQuery?.data?.results?.find((r) => r.isManagingOrg)

  useEffect(() => {
    if (managingOrg) {
      setAutoApproveReferralsEnabled(
        managingOrg.settings.autoApproveReferralsEnabled
      )
      setAllowSuborgUsersToSetupSuborgs(
        managingOrg.settings.allowSuborgUsersToSetupSuborgs
      )
    }
  }, [managingOrg])

  useEffect(() => {
    let draft = {}
    draft.id = managingOrg?.id
    draft.name = managingOrg?.name
    draft.settings = {}
    draft.settings.autoApproveReferralsEnabled = autoApproveReferralsEnabled
    draft.settings.allowSuborgUsersToSetupSuborgs =
      allowSuborgUsersToSetupSuborgs
    orgUpdate.mutate(draft)
  }, [autoApproveReferralsEnabled, allowSuborgUsersToSetupSuborgs])

  useEffect(() => {
    if (checkNewUserInviteEmailandSendInviteEmail.isLoading)
      setShowStatusColorChange('loading')
    if (checkNewUserInviteEmailandSendInviteEmail.isSuccess)
      setShowStatusColorChange('success')
    if (checkNewUserInviteEmailandSendInviteEmail.isError)
      setShowStatusColorChange('error')
    else setShowStatusColorChange(false)
    //console.log(showStatusColorChange)
  }, [newUserEdit, checkNewUserInviteEmailandSendInviteEmail])

  useEffect(() => {
    console.log(userSettingsDraft)
  }, [userSettingsDraft])

  const handleUserSettingsTextUpdate = async (eventOrRef) => {
    const draftObject = { ...userSettingsDraft }
    if (!eventOrRef || !typeof eventOrRef === 'object') return null
    Object.keys(userSettingsDraft).forEach((key) => {
      const setting = { ...userSettingsDraft[key] }
      if (_.isEqual(setting?.ref, eventOrRef)) {
        setting.old =
          eventOrRef.current?.props?.html || eventOrRef.target?.value
        setting.edit = true
      } else {
        setting.edit = false
      }
      draftObject[key] = setting
    })

    setUserSettingsDraft({ ...userSettingsDraft, ...draftObject })
  }

  const handleUSerSettingsSave = (e) => {
    e.preventDefault()
    const keys = Object.keys(userSettingsDraft)
    const newChangesRequirePassword = keys.filter(
      (setting) =>
        userSettingsDraft[setting]?.new &&
        userSettingsDraft[setting]?.requireCurrentPassword
    ).length
    const newActiveChanges = keys.filter(
      (setting) =>
        userSettingsDraft[setting]?.new &&
        userSettingsDraft[setting]?.new !== userSettingsDraft[setting]?.old &&
        userSettingsDraft[setting]?.edit
    )
    const draftUpdates = newActiveChanges.map((setting) => {
      const draft = {
        id: auth()?.id,
        [setting]: userSettingsDraft[setting]?.new
      }
      return draft
    })
    console.log(draftUpdates)
    if (newChangesRequirePassword && !passwordVerified)
      setShowModal({
        heading: 'Please Re-enter Password',
        message: 'Please re-enter your password to save your changes.',
        dismiss: () => setShowModal(false),
        confirm: () => {
          setPasswordVerified(true)
        },
        confirmBox: {
          type: 'password',
          placeholder: 'Password',
          value: userCurrentPassword,
          onChange: (e) => {
            userCurrentPassword.current = e.target.value

            console.log(userCurrentPassword)
          }
        },
        action: 'Confirm'
      })
  }

  const handleSendInviteEmail = async (e) => {
    e.preventDefault()
    const form = e.target
    const from = form.from?.value
    const orgId = form.orgId?.value
    const email = form.email?.value
    try {
      const response = checkNewUserInviteEmailandSendInviteEmail.mutate({
        from,
        email,
        orgId
      })
      if (response) setNewUserEdit(false)
    } catch (err) {
      //console.log(err)
    }
  }

  const handleDeleteUser = (e) => {
    setShowModal({
      heading: 'Delete User',
      message:
        'Are you sure you want to delete this user?  This cannot be undone; however, all their referrals remain.',
      dismiss: () => setShowModal(false),
      confirm: () => userDelete.mutate({ id: e.target.id })
    })
    setStatusMsg(false)
    setStatusMsg({
      heading: 'User deleted',
      message: 'User has been removed.  Note that all their referrals remain.',
      type: 'success'
    })
  }
  const handleDeleteOrg = (e) => {
    setShowModal({
      heading: 'Delete Organization',
      message:
        'Are you sure you want to delete this organization?  This cannot be undone; however, all their referrals remain.',
      dismiss: () => setShowModal(false),
      confirm: () => {
        orgDelete.mutate({ id: e.target.id })
      }
    })
  }

  const handleUserClickAction = (e) => {
    e.preventDefault()
    const { id, email, name } = userQuery.data?.results?.find(
      (result) => result.id === e.target.id
    )
    setDraftUser({ id, email, name })
  }

  const handleOrgClickAction = (e) => {
    //console.log(e.target)
    e.preventDefault()
    const draft = orgQuery.data?.results?.find(
      (result) => result.id === e.target.id
    )
    if (draft) setDraftOrg(draft)
  }

  const handleUpdateText = (e) => {
    //console.log(e)
  }

  // const handleUpdateOrgName = e => {
  //   e.preventDefault()
  //   setDraftGlobalSettings({
  //     ...draftGlobalSettings,
  //     edit: true
  //   })
  // }

  const handleSaveOrg = (e) => {
    e.preventDefault()
    if (draftOrg) orgUpdate.mutate(draftOrg)
    setDraftOrg(null)
  }

  const handleNewOrg = (e) => {
    orgUpdate.mutate({
      name: 'New Organization',
      managingOrg: auth().managingOrgId || auth().orgId || 0
    })
  }

  const handleNewUser = (e) => {
    setNewUserEdit([
      {
        id: 0,
        label: 'Email of new user:',
        name: 'email',
        type: 'email',
        showStatusColor: showStatusColorChange,
        setNewEntry: setNewUserEdit,
        handleNewEntrySave: handleSendInviteEmail,
        hiddenValues: [
          {
            id: 0,
            name: 'managingOrgId',
            type: 'select',
            value: orgQuery?.data?.results?.find((org) => org.isManagingOrg).id
          },
          {
            id: 1,
            name: 'from',
            type: 'select',
            value: auth()?.name
          }
        ]
      }
    ])
  }

  const handleSaveUser = (e) => {
    e.preventDefault()
    // console.log(draftUser)
    if (draftUser) userUpdate.mutate(draftUser)
    setDraftUser(null)
  }
  const user = {
    id: auth()?.id || 0,
    name: auth()?.name || 'Unkown User',
    email: auth()?.email || ''
  }
  const adminConfig = [
    {
      heading: {
        name: 'Admin',
        pageId: 'admin',
        href: '/settings/admin',
        icon: UsersIcon
      },
      dataLists: [
        {
          heading: {
            name: 'Global Settings',
            description: 'Change your organization-wide settings.',
            dataListId: 'admin/globalSettings'
          },
          items: [
            {
              // textGroup: {
              //   label: 'Organization Name',
              //   data:
              //    managingOrg
              //       ?.name || 'No name set yet',
              //   draftEdit: draftGlobalSettings,
              //   setDraftEdit: setDraftGlobalSettings,
              //   onChange: handleUpdateOrgName,
              //   setDraftText: setDraftOrgName,
              //   handleSave: handleSaveOrg,
              //   draftText: draftOrgName,
              //   edit: 'admin',
              //   action: 'Update',
              //   itemId: 'admin/globalSettings/districtName'
              // }
            },
            {
              switchGroup: {
                state: autoApproveReferralsEnabled,
                label: 'Auto-approve Referrals',
                onChange: setAutoApproveReferralsEnabled,
                itemId: 'admin/globalSettings/autoApproveReferrals'
              }
            },
            // {
            //   switchGroup: {
            //     state: allowSuborgUsersToSeePeerReferrals,
            //     label:
            //       'Allow users to see referrals from peers at the same sub-organization.',
            //     onChange: setAllowSuborgUsersToSeePeerReferrals,
            //     itemId:
            //       'admin/globalSettings/allowSuborgUsersToSeePeerReferrals'
            //   }
            // },
            {
              switchGroup: {
                state: allowSuborgUsersToSetupSuborgs,
                label:
                  'Allow sub-organization admins to set up sub-organizations of their own.',
                onChange: setAllowSuborgUsersToSetupSuborgs,
                itemId: 'admin/globalSettings/allowSuborgUsersToSetupSuborgs'
              }
            }
          ]
        },
        {
          heading: {
            name: 'Manage Sub-organizations',
            description:
              'Set up your sub-organizations (like individual schools or programs)',
            dataListId: 'admin/manageSubOrganizations',
            leadingAction: 'Add an organization to your account',
            actionHandler: handleNewOrg
          },
          items: [
            {
              listGroup: {
                label: 'Organizations',
                data: orgQuery?.data?.results?.map((org) => {
                  return {
                    ...org,
                    isCurrentOrg: org.id === user.orgId
                  }
                }) || [{ id: 0, name: 'Loading...' }],
                itemId: 'admin/globalSettings/organizations',
                edit: 'admin',
                draftEdit: draftOrg,
                setDraftEdit: setDraftOrg,
                handleSave: handleSaveOrg,
                handleDelete: handleDeleteOrg,
                actions: [
                  {
                    actionId: 'manageSubOrganizations/editAction',
                    label: 'Edit',
                    handler: handleOrgClickAction
                  }
                ]
              }
            }
          ]
        },
        {
          heading: {
            name: 'Manage Users',
            description: 'Invite, remove, and grant permissions to users.',
            dataListId: 'admin/manageUsers',
            edit: 'admin',
            leadingAction: 'Invite a new user',
            newEntry: newUserEdit,
            handleNewEntrySave: handleSendInviteEmail,
            setNewEntry: setNewUserEdit,
            actionHandler: handleNewUser
          },
          items: [
            {
              listGroup: {
                label: 'Users',
                data:
                  userQuery?.data?.results?.map &&
                  userQuery?.data?.results.map((entry) => {
                    const newEntries = {
                      listBox: InsiteListBox,
                      listBoxData: orgQuery?.data?.results || [],
                      listBoxLabel: entry.orgName || 'Select Org',
                      isCurrentUser: entry.id === user.id
                    }
                    const draft = {
                      ...entry,
                      ...newEntries
                    }
                    return draft
                  }),
                setDraftEdit: setDraftUser,
                draftEdit: draftUser,
                handleSave: handleSaveUser,
                handleDelete: handleDeleteUser,

                itemId: 'admin/globalSettings/users',
                actions: [
                  {
                    actionId: 'admin/globalSettings/users/editAction',
                    label: 'Edit',
                    handler: handleUserClickAction
                  }
                ]
              }
            }
          ]
        },
        {
          heading: {
            name: 'Pending Users',
            description:
              'Here are your pending users.  Once they accept the invite, they will be able to log in and create referrals for your organization.',
            dataListId: 'admin/managePendingUsers',
            edit: 'admin'
          },
          items: [
            {
              listGroup: {
                label: 'Pending Users',
                data: orgQuery?.data?.results
                  ?.find((org) => {
                    return org.isManagingOrg
                  })
                  ?.pendingInvites.map((pi, i) => {
                    return { name: pi, id: i }
                  }),
                setDraftEdit: setDraftOrg,
                draftEdit: draftOrg,
                handleSave: handleSaveOrg,
                handleDelete: handleDeleteOrg,

                itemId: 'admin/globalSettings/users',
                actions: [
                  {
                    actionId: 'admin/globalSettings/users/editAction',
                    label: 'Edit',
                    handler: handleOrgClickAction
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ]

  const nonAdminConfig = [
    {
      heading: {
        name: 'General',
        pageId: 'general',
        href: '/settings/general',
        icon: UserCircleIcon
      },
      dataLists: [
        {
          heading: {
            name: 'Profile',
            description:
              'This information will be available your district admins and InSite employees.',
            dataListId: 'general/profile'
          },
          items: [
            {
              textGroup: {
                label: 'Full Name',
                handleSave: handleUSerSettingsSave,
                edit: 'user',
                ref: userName,
                superState: userSettingsDraft,
                setSuperState: setUserSettingsDraft,
                superStateKey: 'name',
                onChange: handleUserSettingsTextUpdate,
                validationFn: (value) =>
                  !value?.length || NAME_REGEX.test(value),
                data: user.name,
                action: 'Update',
                itemId: 'general/profile/name'
              }
            },
            {
              switchGroup: {
                state: automaticTimezoneEnabled,
                label: 'Automatic Timezone',
                onChange: setAutomaticTimezoneEnabled,
                itemId: 'general/profile/timezone'
              }
            }
          ]
        }
      ]
    },
    {
      heading: {
        name: 'Security',
        pageId: 'security',
        href: '/settings/security',
        icon: FingerPrintIcon
      },
      dataLists: [
        {
          heading: {
            name: 'Password and Authentication',
            description:
              'Change your password or set two-factor authentication',
            dataListId: 'security/authenticationAndPassword'
          },
          items: [
            {
              textGroup: {
                superState: userSettingsDraft,
                handleSave: handleUSerSettingsSave,
                setSuperState: setUserSettingsDraft,
                superStateKey: 'password',
                onChange: handleUserSettingsTextUpdate,
                invalidMessage:
                  'Password must be at least 8 characters and contain at least one number and one special character.',

                ref: userPassword,
                confirm: {
                  ref: userConfirmPassword,
                  invalidMessage: 'Passwords must match.',
                  label: 'Confirm Password',
                  type: 'password',
                  validationFn: (value) => {
                    if (!value) return true
                    if (
                      userPassword?.current?.value ===
                      userConfirmPassword?.current?.value
                    )
                      return true
                    return false
                  }
                },
                label: 'Password',
                edit: 'user',
                data: '',
                type: 'password',
                validationFn: (value) => {
                  const password = value
                  if (!password) return true
                  if (PWD_REGEX.test(password)) return true

                  return false
                },

                action: 'Update',
                itemId: 'authenticationAndPassword/password'
              }
            },
            {
              switchGroup: {
                state: twoFactorAuthenticationEnabled,
                label: 'Two-factor Authentication',
                onChange: setTtwoFactorAuthenticationEnabled,
                itemId: 'security/authenticationAndPassword/2FA'
              }
            },
            {
              textGroup: {
                superState: userSettingsDraft,
                setSuperState: setUserSettingsDraft,
                superStateKey: 'phoneNumber',
                onChange: handleUserSettingsTextUpdate,
                handleSave: handleUSerSettingsSave,
                label: 'Phone Number',
                type: 'tel',
                validationFn: (value) =>
                  !value?.length || PHONE_REGEX.test(value),
                invalidMessage:
                  'Phone number must be 10 digits and of the format (555)123-4567.  ',
                ref: userPhoneNumber,
                data: user?.phoneNumber || '',
                edit: 'user',
                autoComplete: 'tel-national',
                showState: {
                  state: twoFactorAuthenticationEnabled
                },
                action: 'Update',
                itemId: 'admin/authenticationAndPassword/2FAnumber'
              }
            }
          ]
        }
      ]
    }
  ]

  const pageConfig =
    auth()?.role === 'admin' || auth()?.role === 'userAdmin'
      ? [...nonAdminConfig, ...adminConfig]
      : [...nonAdminConfig]

  const secondaryNavigation = pageConfig.map((page) => {
    return page.heading
  })

  const currentPage = pageConfig.find(
    (pageObj) => pageObj.heading?.pageId === page
  )

  return (
    <>
      <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => {
                if (!item) return <div key={Math.random()}> </div>
                return (
                  <li key={item.pageId}>
                    <a
                      href={item.href}
                      className={classNames(
                        item?.pageId?.toLowerCase() === page
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-indigo-600'
                            : 'text-gray-400 group-hover:text-indigo-600',
                          'h-6 w-6 shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            {currentPage?.dataLists?.map((dataList) => {
              return (
                <DataList
                  key={dataList.heading.dataListId}
                  heading={dataList.heading}
                  items={dataList.items}
                />
              )
            })}
          </div>
        </main>
      </div>
      {showModal ? (
        <Modal
          dismiss={showModal.dismiss}
          heading={showModal.heading}
          message={showModal.message}
          confirm={showModal.confirm}
          action={showModal.action}
          confirmBox={showModal.confirmBox}
        ></Modal>
      ) : (
        ''
      )}
      {statusMsg ? (
        <Notification
          handler={setStatusMsg}
          heading={statusMsg.heading}
          message={statusMsg.message}
          type={statusMsg.type}
        ></Notification>
      ) : (
        ''
      )}
    </>
  )
}

const DataList = ({ heading, items }) => {
  const GenerateSwitchGroup = ({ label, state, onChange, itemId }) => {
    return (
      <Switch.Group as="div" className="flex pt-6 p-2" key={itemId}>
        <Switch.Label
          as="dt"
          className="flex-none pr-6 font-medium text-gray-900 sm:w-64"
          passive
          key={itemId + 'label'}
        >
          {label}
        </Switch.Label>
        <dd
          className="flex flex-auto items-center justify-end"
          key={itemId + 'dd'}
        >
          <Switch
            key={itemId + 'switch'}
            checked={state}
            onChange={onChange}
            className={classNames(
              state ? 'bg-indigo-600' : 'bg-gray-200',
              'flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            )}
          >
            <span
              key={itemId + 'span'}
              aria-hidden="true"
              className={classNames(
                state ? 'translate-x-3.5' : 'translate-x-0',
                'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </dd>
      </Switch.Group>
    )
  }

  const GenerateTextGroup = ({
    label,
    data,
    itemId,
    ref,
    action,
    showState,
    onChange,
    draftEdit,
    handleSave,
    superState,
    setSuperState,
    superStateKey,
    autoComplete,
    validationFn,
    confirm,
    invalidMessage
  }) => {
    const state = superState?.[superStateKey]
    if (
      showState &&
      Object.hasOwn(showState, 'state') &&
      showState.state === false
    )
      return <div key={itemId}></div>
    else
      return (
        <div
          className={`pt-6 p-2 sm:flex ${
            draftEdit?.edit ? 'bg-yellow-100 rounded-lg' : ''
          }`}
          key={itemId}
        >
          <dt
            className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6"
            key={itemId + 'dt'}
          >
            {label}
          </dt>
          <dd
            className={
              (state?.edit ? ' ring-1 ring-yellow-500 rounded-md  ' : ' ') +
              ' p-2 mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto'
            }
            key={itemId + 'dd'}
          >
            {state && state.edit && state.confirm ? 'New ' + label : ''}

            <div className="col-span-2">
              <input
                disabled={state && !state.edit}
                value={
                  state?.edit && state?.new
                    ? state?.new || ''
                    : state?.old || data
                }
                className={
                  (state && state.edit ? ' bg-yellow-300 ' : '') +
                  'text-gray-900 p-2 mt-3 rounded-md ' +
                  (validationFn
                    ? validationFn(state?.new)
                      ? ' ring-1 ring-green-500 '
                      : ' ring-2 ring-red-500 '
                    : '')
                }
                key={itemId + 'div2'}
                ref={state?.ref}
                placeholder={
                  state?.edit && state?.new
                    ? state?.new
                    : data || (state.maskValues ? '[hidden]' : '[None]')
                }
                type={state?.type}
                autoComplete={autoComplete || 'off'}
                onChange={(e) => {
                  const dot = '\u2022'
                  const newText = e?.target?.value || ''
                  const maskedValues =
                    newText?.length && typeof newText === 'string'
                      ? newText
                          .split('')
                          .map((char) => dot)
                          .join('')
                      : '***'
                  const updatedState = {
                    new: newText,
                    maskedValues
                  }
                  const draft = {
                    ...superState,
                    [superStateKey]: {
                      ...state,
                      ...updatedState
                    }
                  }

                  setSuperState(draft)
                }}
              ></input>
              {validationFn && !validationFn(state?.new) ? (
                <div className="text-red-500 pt-1 ">
                  {invalidMessage || `Invalid ${label}`}
                </div>
              ) : (
                ''
              )}
            </div>
            <div
              className={
                state && state.edit && state.confirm ? ' flex ' : ' invisible '
              }
            >
              <div className={'px-2 pt-2'}>{'Confirm ' + label}</div>
              <div className={''}>
                <input
                  className={'p-2 mt-3 rounded-md bg-yellow-300 flex-grow'}
                  type={confirm?.type}
                  name={confirm?.name}
                  ref={confirm?.ref}
                  placeholder={confirm?.placeholder}
                  onChange={(e) => {
                    const newText = e?.target?.value || ''
                    const draftRef = { ...confirm?.ref }
                    draftRef.current.value = newText
                    const draft = {
                      ...superState,
                      [superStateKey]: {
                        ...state,
                        confirm: draftRef
                      }
                    }
                    setSuperState(draft)
                  }}
                />
                {confirm &&
                confirm.validationFn &&
                !confirm.validationFn(state?.confirm?.current?.value) ? (
                  <div className="text-red-500 pt-1 ">
                    {confirm?.invalidMessage || `Invalid ${confirm.label}`}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {state && !state.edit ? (
              <button
                type="button"
                id={itemId}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
                key={itemId + 'button'}
                onClick={(e) => {
                  if (state?.ref) return onChange(ref)
                  return onChange(e)
                }}
              >
                {action}
              </button>
            ) : (
              ''
            )}
            {state?.edit || draftEdit?.edit ? (
              <div className="flex ">
                <button
                  id={itemId}
                  className="flex m-1 mx-4 text-slate-400 "
                  onClick={() => {
                    const draft = { ...superState }
                    draft[superStateKey].edit = false
                    draft[superStateKey].old = null
                    draft[superStateKey].new = -null
                    if (draft[superStateKey].confirm)
                      draft[superStateKey].confirm.current.value = ''
                    setSuperState(draft)
                  }}
                >
                  {' '}
                  Cancel
                </button>
                <button
                  id={itemId}
                  className={
                    (validationFn &&
                    validationFn(state?.new) &&
                    state?.new &&
                    (!confirm ||
                      (confirm &&
                        state?.confirm?.current?.value &&
                        confirm?.validationFn &&
                        confirm?.validationFn(state?.confirm?.current?.value)))
                      ? ' text-green-500 '
                      : ' text-red-100 ') + 'flex m-1'
                  }
                  onClick={handleSave}
                >
                  {' '}
                  Save
                </button>
              </div>
            ) : (
              ''
            )}
          </dd>
          <dd></dd>
        </div>
      )
  }

  if (!items)
    return <div key={heading.dataListId + 'error'} className="ServerError" />
  return (
    <>
      <div key={heading.dataListId + 'divwrapper'}>
        <h2
          className="text-base font-semibold leading-7 text-gray-900"
          key={heading.dataListId + 'h2'}
        >
          {heading.name}
        </h2>
        <p
          className="mt-1 text-sm leading-6 text-gray-500"
          key={heading.dataListId + 'p'}
        >
          {heading.description}
        </p>
        {heading.leadingAction ? (
          <div
            className="flex border-t border-gray-100 pt-6"
            key={heading.dataListId + 'div header '}
          >
            {!heading.newEntry || heading.newEntry.hide ? (
              <button
                onClick={heading.actionHandler}
                type="button"
                className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                key={heading.dataListId + 'buttonfooter'}
              >
                <span
                  aria-hidden="true"
                  key={heading.dataListId + 'spanfooter'}
                >
                  +
                </span>{' '}
                {heading.leadingAction}
              </button>
            ) : (
              <div className="rounded-md mx-auto p-2 w-full">
                {heading.newEntry.map((entry) => {
                  return (
                    <form
                      className={`${entry?.showStatusColor === 'error' ? 'ring-red-500' : 'ring-gray-500 '} rounded-md ring-2 p-2`}
                      key={entry.id}
                      onSubmit={entry.handleNewEntrySave}
                    >
                      {entry.hiddenValues &&
                        entry.hiddenValues.map((hiddenValue, idx) => {
                          return (
                            <input
                              key={hiddenValue + idx}
                              type="hidden"
                              value={hiddenValue.value}
                              name={hiddenValue.name}
                            />
                          )
                        })}
                      <label className="flex w-full" htmlFor={entry}>
                        {entry.label}{' '}
                      </label>
                      <input
                        name={entry.name}
                        required={true}
                        type={entry.type}
                        id={entry}
                        className={`${entry.showStatusColor === 'error' ? 'bg-red-200' : ''} signin flex p-2 mx-auto`}
                      />
                      <button
                        type="reset"
                        disabled={entry.showStatusColor === 'loading'}
                        className=" rounded-md bg-indigo-50 p-2 my-2"
                        onClick={() => {
                          heading.setNewEntry(null)
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className={`rounded-md text-white  p-2  m-2 bg-indigo-500`}
                      >
                        Send Invite Email
                      </button>
                    </form>
                  )
                })}
              </div>
            )}
          </div>
        ) : (
          ''
        )}

        <dl
          className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
          key={heading.dataListId + 'data label'}
        >
          {items.map((item) => {
            if (item.switchGroup) return GenerateSwitchGroup(item.switchGroup)
            if (item.textGroup) return GenerateTextGroup(item.textGroup)
            if (item.listGroup) return GenerateListGroup(item.listGroup)
            else return <div key={heading.dataListId + 'empty'}></div>
          })}
        </dl>
        {heading.action ? (
          <div
            className="flex border-t border-gray-100 pt-6"
            key={heading.dataListId + 'divfooter'}
          >
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              key={heading.dataListId + 'buttonfooter'}
            >
              <span aria-hidden="true" key={heading.dataListId + 'spanfooter'}>
                +
              </span>{' '}
              {heading.action}
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

const GenerateListGroup = ({
  data,
  draftEdit,
  itemId,
  actions,
  setDraftEdit,
  handleSave,
  handleDelete
}) => {
  return (
    <ul className="mt-2 divide-y divide-gray-100" key={itemId}>
      {data?.map &&
        data.map((entry) => {
          if (!entry || !entry.id) return <div key={Math.random()}></div>
          else
            return (
              <li
                key={entry.id}
                className={
                  (entry.isManagingOrg
                    ? ' ring my-4 ring-green-600 rounded-md'
                    : '') +
                  ` flex justify-between gap-x-6 px-2 py-5 ${
                    draftEdit?.id === entry.id
                      ? '  bg-yellow-100 rounded-lg '
                      : ''
                  }`
                }
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="h-10 w-10  rounded-full bg-gray-50">
                    <InitialsAvatar
                      className="mt-2 ml-3"
                      name={
                        entry?.name &&
                        (entry?.name?.replace(/\s+/g, ' ').trim() || 'No Name')
                      }
                      alt=""
                    />
                  </div>
                  <div className="min-w-0 flex-auto">
                    <ContentEditable
                      disabled={!(draftEdit?.id === entry.id)}
                      className={`${
                        entry?.isManagingOrg ? 'bg-yellow-50' : ' '
                      } "text-sm font-semibold rounded-md  text-gray-900 mx-auto "`}
                      html={
                        draftEdit?.id === entry.id
                          ? draftEdit?.name || 'No Name'
                          : `${entry?.name || 'No Name'}`
                      }
                      onChange={(e) => {
                        setDraftEdit({
                          ...draftEdit,
                          name: e.target.value
                        })
                      }}
                    ></ContentEditable>
                    {['admin', 'userAdmin'].includes(entry?.role) ? (
                      <div className="flex place-items-center gap-x-1.5">
                        <span className="flex-none rounded-full bg-emerald-500/20 p-1">
                          <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                        </span>
                        <p className="text-xs leading-5 text-gray-500">Admin</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    {entry.isManagingOrg ? (
                      <div className="flex place-items-center gap-x-1.5">
                        <span className="flex-none rounded-full bg-emerald-500/20 p-1">
                          <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                        </span>
                        <p className="text-xs leading-5 text-gray-500">
                          Managing Org
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}{' '}
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {entry?.email}
                    </p>
                  </div>
                </div>
                {entry.listBox && !(draftEdit?.id === entry.id)
                  ? entry.orgName || 'No org assigned yet'
                  : ''}
                {entry.listBox ? (
                  <div
                    className={`${
                      !(draftEdit?.id === entry.id) ? 'invisible' : ''
                    } hidden shrink-0 sm:flex sm:flex-col sm:items-end'`}
                  >
                    <entry.listBox
                      label={entry.listBoxLabel}
                      data={entry.listBoxData}
                      onClick={({ id, name, event }) => {
                        setDraftEdit({
                          ...draftEdit,
                          orgId: id,
                          orgName: name
                        })
                        // console.log(draftEdit)
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <div className="mt-1 flex items-center gap-x-1.5">
                    {draftEdit?.id === entry.id ? (
                      <div className="flex ">
                        <button
                          id={entry.id}
                          className={`${
                            entry.isManagingOrg ||
                            entry.isCurrentOrg ||
                            entry.isCurrentUser
                              ? 'invisible'
                              : ''
                          } flex rounded-md bg-red-500 text-white p-2 m-2`}
                          onClick={handleDelete}
                          value={entry}
                        >
                          {' '}
                          Delete
                        </button>
                        <button
                          id={entry.id}
                          className=" rounded-md bg-indigo-50 p-2 m-2"
                          onClick={() => setDraftEdit(null)}
                          value={entry}
                        >
                          {' '}
                          Cancel
                        </button>

                        <button
                          id={entry.id}
                          className={
                            ' rounded-md bg-indigo-500 text-white p-2 m-2'
                          }
                          onClick={handleSave}
                          value={entry}
                        >
                          {' '}
                          Save
                        </button>
                      </div>
                    ) : (
                      <div className="flex">
                        {actions &&
                          actions.map((action) => {
                            return (
                              <button
                                id={entry.id}
                                className="flex"
                                onClick={action.handler}
                                key={action.actionId}
                                value={entry}
                              >
                                <div id={entry.id}>{action.label} </div>
                              </button>
                            )
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </li>
            )
        })}
    </ul>
  )
}

export default SettingsPage
