import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Missing from './Missing'
import { axiosPublic as axios } from '../api/axios'
import Login from './Login'
const VERIFY_EMAIL_URL = '/auth/verify-email'

export const VerifyEmailPage = () => {
  const params = useParams()
  const token = params.token
  const [isResponse, setIsResponse] = useState(false)

  const tryVerify = async (token) => {
    try {
      const response = await axios.post(
        VERIFY_EMAIL_URL,
        {},
        {
          params: { token },
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      )
      setIsResponse(true)
      return response
    } catch (error) {
      // console.log(error.response)
      // console.log(`Error verifying email ${error.response?.data?.message}`);
      setIsResponse(false)
    }
  }

  useEffect(() => {
    tryVerify(token)
  }, [])

  if (!token) return <Missing />
  else if (isResponse) {
    return <Login statusAlert="Your email has been verified!" />
  } else {
    return (
      <div>
        <h1>Your email could not be verified.</h1>
        <h3>
          Please check the link in your email and try again. If you already have
          an account, please{' '}
          <a className="underline" href="/login">
            login
          </a>{' '}
          here.
        </h3>
      </div>
    )
  }
}
