import { faker } from '@faker-js/faker'
import { axiosPublic } from '../api/axios'
faker.seed(337)

export let loadDemoFaqs = () => {
  let faqs = [...Array(20)]
  return faqs.map((faq) => {
    faq = {}
    faq.question = faker.lorem.sentence()
    faq.answer = faker.lorem.paragraphs()
    return faq
  })
}

const getSynchedVersion = async (value) => {
  return await axiosPublic.get('/statics/all').then((res) => {
    const { data } = res
    if (data && typeof data === 'object') {
      if (Object.keys(data).includes(value)) return data[value]
      else return backedUpVersion[value]
    }
  })
}

export const localPayers = [
  { id: 0, label: 'BCBS-Anthem' },
  { id: 1, label: 'BCBS-IL (HMO)' },
  { id: 2, label: 'BCBS-NJ' },
  { id: 3, label: 'BCBS-NJ (PPO)' },
  { id: 4, label: 'BCBS-NJ - FEP' },
  { id: 5, label: 'BCBS-NJ - NJ Direct (PPO)' },
  { id: 6, label: 'Borough of Elmer-Police Dept' },
  { id: 7, label: 'Christian Brothers Services' },
  { id: 8, label: 'Cigna' },
  { id: 9, label: 'Cigna (PPO)' },
  { id: 10, label: 'GEHA' },
  { id: 11, label: 'GWH-Cigna - Cigna' },
  { id: 12, label: 'Horizon NJ Health' },
  { id: 13, label: 'Humana Medicare Advantage Plan' },
  { id: 14, label: 'Self Pay' },
  { id: 15, label: 'Tricare' },
  { id: 16, label: 'Trustmark' },
  { id: 17, label: 'UHC-Community Plan' },
  { id: 18, label: 'United Healthcare/Optum' },
  { id: 19, label: 'United of Omaha' },
  { id: 20, label: 'Wellcare Health Plans of New Jersey' },
  { id: 21, label: 'Medicaid' }
]

export let localStatusLabels = [
  { id: 0, label: 'Referred', statusColor: 'green' },
  { id: 1, label: 'Scheduled', statusColor: 'green' },
  { id: 2, label: 'Canceled', statusColor: 'red' },
  { id: 3, label: 'Pending Report', statusColor: 'green' },
  { id: 4, label: 'Cleared', statusColor: 'green' },
  { id: 5, label: 'Not Cleared', statusColor: 'red' }
]

export let localProviders = [
  { id: 0, name: 'Alfina Evans, MSN, APN, PMHNP-BC' },
  { id: 1, name: 'Aaron Z Spector, MSN, APN, PMHNP-BC' },
  { id: 2, name: 'Corine Iwuala' },
  { id: 3, name: 'Diane Burt, PMHNP' },
  { id: 4, name: 'Ernst Renondeau, PMHNP' },
  { id: 5, name: 'Joshua Schorr, MSN, APN, PMHNP-BC' },
  { id: 6, name: 'Lisa Ewan, PMHNP' },
  { id: 7, name: 'Michelle Guarnizo, APN, PMHNP-BC' },
  { id: 8, name: 'Matthew Minteer, MSN, APN, PMHNP-BC' },
  { id: 9, name: 'Mary Pajic, LCSW' },
  { id: 10, name: 'Steven Padula, MSN, APN, PMHNP-BC' },
  { id: 11, name: 'Tomeka Mapp, MSN, APN PMHNP-BC' }
]

export let localBiologicalSexDescriptors = [
  { id: 0, label: 'male' },
  { id: 1, label: 'female' },
  { id: 2, label: 'other' },
  { id: 3, label: 'unknown' }
]

export let localAllowedDurations = [
  { id: 0, label: '30 Minutes' },
  { id: 1, label: '60 Minutes' }
]

export let localAppointmentTypes = [
  { id: 0, label: 'Initial Evaluation' },
  { id: 1, label: 'Follow Up' },
  { id: 2, label: 'Medication Management' },
  { id: 3, label: 'Telepsychiatry' },
  { id: 4, label: 'Substance Abuse Evaluation' },
  { id: 5, label: 'Suboxone Treatment' }
]

export let localAlternativesToInsiteCareDescriptors = [
  { id: 0, label: 'Hospital or Urgent Care' },
  {
    id: 1,
    label: 'No care rendered'
  },
  { id: 2, label: 'Other non-urgent provider' }
]

export let localReferralReasonDescriptors = [
  { id: 0, label: 'School Clearance' },
  { id: 1, label: 'Psychiatric Evaluation' },
  { id: 2, label: 'Medication Management' },
  { id: 3, label: 'Therapy' },
  { id: 4, label: 'IEP' },
  { id: 5, label: 'Other' }
]

export let localStudentTypeDescriptors = [
  { id: 0, label: 'General Ed' },
  { id: 1, label: 'Special Ed' }
]

export let localReasonForCareDescriptors = [
  { id: 0, label: 'Depression' },
  { id: 1, label: 'Anxiety' },
  { id: 2, label: 'PTSD' },
  { id: 3, label: 'ADHD' },
  { id: 4, label: 'Long-term Illness' },
  { id: 5, label: 'Bullying' },
  { id: 6, label: 'Violence' },
  { id: 7, label: 'Social Media' },
  { id: 8, label: 'Other' }
]

let backedUpVersion = {
  payers: localPayers,
  statusLabels: localStatusLabels,
  providers: localProviders,
  biologicalSexDescriptors: localBiologicalSexDescriptors,
  allowedDurations: localAllowedDurations,
  appointmentTypes: localAppointmentTypes,
  alternativesToInsiteCareDescriptors: localAlternativesToInsiteCareDescriptors,
  referralReasonDescriptors: localReferralReasonDescriptors,
  studentTypeDescriptors: localStudentTypeDescriptors,
  reasonForCareDescriptors: localReasonForCareDescriptors
}

export const payers = getSynchedVersion('payers')
export const statusLabels = getSynchedVersion('statusLabels')
export const providers = getSynchedVersion('providers')
export const biologicalSexDescriptors = getSynchedVersion(
  'biologicalSexDescriptors'
)
export const allowedDurations = getSynchedVersion('allowedDurations')
export const appointmentTypes = getSynchedVersion('appointmentTypes')
export const alternativesToInsiteCareDescriptors = getSynchedVersion(
  'alternativesToInsiteCareDescriptors'
)
export const referralReasonDescriptors = getSynchedVersion(
  'referralReasonDescriptors'
)
export const studentTypeDescriptors = getSynchedVersion(
  'studentTypeDescriptors'
)
export const reasonForCareDescriptors = getSynchedVersion(
  'reasonForCareDescriptors'
)
