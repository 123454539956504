import { useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ErrorAlert, StatusAlert } from './subcomponents/Alert'
import { axiosPublic as axios } from '../api/axios'
const FORGOT_PASSWORD_URL = '/auth/reset-password'
const SEND_PASSWORD_RESET_LINK_URL = '/auth/forgot-password'

export const ForgotPassword = () => {
  const { token } = useParams()
  const [currentToken, setCurrentToken] = useState(token)

  useEffect(() => {}, [currentToken])

  if (typeof currentToken === 'undefined') return SendEmail()
  else return ResetPassword({ token: currentToken, setToken: setCurrentToken })
}

const SendEmail = () => {
  const emailRef = useRef()
  const [email, setEmail] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [statusMsg, setStatusMsg] = useState('')
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    // emailRef.current.focus();
  }, [])

  useEffect(() => {
    // emailRef.current.focus();
  }, [submitted])

  useEffect(() => {
    // setErrMsg(errMsg);
    // errRef.current?.focus();
  }, [errMsg])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setSubmitted(true)
      const response = await axios.post(
        SEND_PASSWORD_RESET_LINK_URL,
        JSON.stringify({ email }),
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: false
        }
      )
      // console.log(JSON.stringify(response?.data));
      // console.log(JSON.stringify(response));
      setErrMsg('')
      setSubmitted(false)
      setStatusMsg(
        `We sent a reset password link to your mail. ${response.data}`
      )
    } catch (err) {
      setStatusMsg('')
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 400) {
        setErrMsg(
          'The server is having a hard time understanding that reset request.'
        )
      } else if (err.response?.status === 404) {
        setErrMsg("We couldn't find that email address. ")
      } else if (err.response?.status === 401) {
        setErrMsg(
          'The server returned "Unauthorized".  Please check the link try again, or try sending the link again'
        )
      } else if (err.response?.status === 429) {
        setErrMsg('Too many  attempts.')
      } else {
        setErrMsg('An error occured')
      }
      setSubmitted(false)
    }
  }
  if (submitted)
    return (
      <div>
        <h1>Submitting...</h1>
      </div>
    )
  else
    return (
      <>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src="/InSite-Favicon.svg"
              alt="InSite Health"
            />
            <h2 className="signin">Forgot Password</h2>
          </div>
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <ErrorAlert message={errMsg} />
            <StatusAlert message={statusMsg} />
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              className={'space-y-6 ' + (submitted ? 'invisible' : '')}
              action="#"
              onSubmit={handleSubmit}
            >
              <div>
                <label htmlFor="email" className="signin">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    ref={emailRef}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="signin"
                  />
                </div>
              </div>

              <div>
                <button type="submit" className="signin" disabled={submitted}>
                  Send Reset Password Link
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-400">
              New to InSite?{' '}
              <a href="/register" className="signin">
                Register for a new account.
              </a>
            </p>
          </div>
        </div>
      </>
    )
}

const ResetPassword = ({ token, setToken }) => {
  const pwdRef = useRef()
  const confirmPwdRef = useRef()

  const errRef = useRef()
  // const statusRef = useRef();
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [confirmPwd, setConfirmPwd] = useState('')

  const [errMsg, setErrMsg] = useState('')
  const [statusMsg, setStatusMsg] = useState('')
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    pwdRef.current.focus()
  }, [])

  useEffect(() => {
    pwdRef.current.focus()
  }, [submitted])

  useEffect(() => {
    // setErrMsg(errMsg);
    errRef.current?.focus()
  }, [errMsg])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setSubmitted(true)
      const response = await axios.post(
        FORGOT_PASSWORD_URL,
        JSON.stringify({ password: pwd }),
        {
          params: {
            token
          },
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: false
        }
      )
      // console.log(JSON.stringify(response?.data));
      // console.log(JSON.stringify(response));
      setSubmitted(true)
      setStatusMsg(
        'Your password has been reset.  You can now login with your new password.'
      )
    } catch (err) {
      setStatusMsg('')
      if (!err?.response) {
        setErrMsg(
          'No Server Response.  Are you still connected to the internet?'
        )
      } else if (err.response?.status === 400) {
        setErrMsg('Please choose a new password and try again')
      } else if (err.response?.status === 404) {
        setErrMsg("We couldn't find that email address.")
      } else if (err.response?.status === 401) {
        setErrMsg(
          'It looks like that link has expired.  Please try again via the "resend" link below.'
        )
      } else if (err.response?.status === 429) {
        setErrMsg('Too many login attempts')
      } else {
        setErrMsg('An error occured')
      }
      setSubmitted(false)
    }
  }
  // const redirect = direct('/forgot-password');

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="/InSite-Favicon.svg"
            alt="InSite Health"
          />
          <h2 className="signin">Reset Password</h2>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm pt-5">
          <ErrorAlert message={errMsg} />
          <StatusAlert message={statusMsg} />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className={!submitted ? 'invisible' : ''}>
            <h3>Please</h3>
          </div>
          <form
            className={`space-y-6 ${submitted ? 'invisible' : ''}`}
            action="#"
            onSubmit={handleSubmit}
          >
            {email ? (
              <div>
                <label htmlFor="email" className="signin">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    disabled
                    value={email}
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="signin"
                  />
                </div>
              </div>
            ) : (
              ''
            )}

            <div>
              <label htmlFor="password" className="signin">
                New Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  ref={pwdRef}
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  name="password"
                  type="password"
                  autoComplete="password"
                  required
                  className="signin"
                />
              </div>
            </div>

            <div>
              <label htmlFor="confirmpassword" className="signin">
                Confirm New Password
              </label>
              <div className="mt-2">
                <input
                  id="confirmpassword"
                  ref={confirmPwdRef}
                  onChange={(e) => setConfirmPwd(e.target.value)}
                  value={pwd}
                  name="pwd"
                  type="password"
                  autoComplete="password"
                  required
                  className="signin"
                />
              </div>
            </div>

            <div>
              <button type="submit" className="signin" disabled={submitted}>
                Reset Password
              </button>
            </div>
          </form>

          <div className="mt-10 text-center text-sm text-gray-400">
            <a className="flex-shrink" href={'/forgot-password'}>
              <p className="underline">Resend the reset password link.</p>
            </a>
          </div>

          <p className="mt-10 text-center text-sm text-gray-400">
            New to InSite?{' '}
            <a href="/register" className="signin">
              Register for a new account.
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
