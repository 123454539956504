import { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ErrorAlert, StatusAlert } from './subcomponents/Alert'
import { axiosPublic as axios } from '../api/axios'
import { useSignIn, useIsAuthenticated } from 'react-auth-kit'

const LOGIN_URL = '/auth/login'

const Login = ({ statusAlert }) => {
  const signIn = useSignIn()
  const isAuthenticated = useIsAuthenticated()
  const redirect = (path) => {
    setTimeout(() => {
      window.location.href = path
    }, 500)
  }
  const location = useLocation()
  let from = location.state?.from?.pathname || '/'
  if (from === '/login') from = '/' //avoids loop

  const emailRef = useRef()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [statusMsg, setStatusMsg] = useState(statusAlert || '')

  const handleNotifcationPermission = () => {
    if (!('Notification' in window)) {
      throw new Error('This browser does not support desktop notification')
    } else {
      return Notification.requestPermission()
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password }),
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      )
      // console.log(JSON.stringify(response?.data));
      // console.log(JSON.stringify(response));
      const notificationPermissions = await handleNotifcationPermission().catch(
        console.error
      )
      const accessToken = response?.data?.tokens?.access?.token
      const accessTokenExpirey = response?.data?.tokens?.access?.expires
      const role = response?.data?.user?.role
      const name = response?.data?.user?.name
      const resEmail = response?.data?.user?.email
      const orgId = response?.data?.user?.orgId
      const managingOrgId = response?.data?.user?.managingOrgId
      const id = response?.data?.user?.id || response?.data?.user?._id
      const refreshToken = response?.data?.tokens?.refresh?.token
      const refreshTokenExpirey = response?.data?.tokens?.refresh?.expires
      const expireyInMinutes =
        (new Date(accessTokenExpirey) - new Date()) / 60000
      const refreshExpireyInMinutes =
        (new Date(refreshTokenExpirey) - new Date()) / 60000
      if (
        signIn({
          token: accessToken,
          refreshToken: refreshToken,
          refreshTokenExpireIn: refreshExpireyInMinutes,
          expiresIn: expireyInMinutes,
          tokenType: 'Bearer',
          authState: {
            id,
            email: resEmail,
            role,
            name,
            orgId,
            managingOrgId,
            notificationPermissions
          }
        })
      ) {
        setStatusMsg(`Login Successful.  Redirecting to ${from}...`)
        return redirect('/login')
      } else {
        return setErrMsg('Login Failed')
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response.  Please try again later.')
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password')
      } else if (err.response?.status === 401 || err.response?.status === 404) {
        setErrMsg(`Wrong username or password. Please try again.`)
      } else if (err.response?.status === 429) {
        setErrMsg('Too many login attempts.  PLease try again in an hour.')
      } else {
        setErrMsg(
          `Login Failed: ${err.response?.status} ${err.response?.statusText}`
        )
      }
    }
  }
  if (isAuthenticated()) {
    return redirect(from)
  }
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="/InSite-Favicon.svg"
            alt="InSite Health"
          />
          <h2 className="signin">Sign in to your account</h2>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm pt-5">
          <ErrorAlert message={errMsg} />
          <StatusAlert message={statusMsg} />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="signin">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  ref={emailRef}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="signin"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="signin">
                  Password
                </label>
                <div className="text-sm">
                  <a href="/forgot-password" className="signin">
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="signin"
                />
              </div>
            </div>
            <div>
              <p>
                By signing in, you agree to our{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.insitehealth.net/terms-of-use/"
                  className="signin"
                >
                  Terms of Use
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.insitehealth.net/privacy-policy/"
                  className="signin"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>

            <div>
              <button type="submit" className="signin">
                Sign in
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-400">
            New to InSite?{' '}
            <a href="/register" className="signin">
              Register for a new account.
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default Login
