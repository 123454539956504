import { Fragment, useState, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAuthUser } from 'react-auth-kit'
import InitialsAvatar from 'react-initials-avatar'
import ContentEditable from 'react-contenteditable'
import { Listbox, Transition } from '@headlessui/react'
import {
  DocumentTextIcon,
  CalendarDaysIcon,
  ArrowDownTrayIcon,
  ChevronUpDownIcon,
  CheckIcon,
  TrashIcon,
  DocumentPlusIcon
} from '@heroicons/react/20/solid'
import { Notification } from './Notification'
import _, { set } from 'lodash'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  fetchPatient,
  updatePatient,
  uploadFile,
  getFile,
  deleteFile
} from '../api/patients'

import { fetchOrg } from '../api/orgs'
import {
  payers as getPayers,
  statusLabels as getStatusLabels,
  biologicalSexDescriptors as getBiologicalSexDescriptors,
  providers as getProviders,
  alternativesToInsiteCareDescriptors as getAlternativesToInsiteCareDescriptors,
  appointmentTypes as getAppointmentTypes,
  referralReasonDescriptors as getReferralReasonDescriptors,
  studentTypeDescriptors as getStudentTypeDescriptors,
  reasonForCareDescriptors as getReasonForCareDescriptors
} from '../api/statics'
import Spinner from './Spinner'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DetailPage = () => {
  const { patientId } = useParams()
  const attachFileRef = useRef()
  const [draftPatient, setDraftPatient] = useState({})
  const [notification, setNotification] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [org, setOrg] = useState()

  const dummy = { id: 999, name: 'Please select', label: 'Please select' }

  const [payers, setPayers] = useState([])
  const [statusLabels, setStatusLabels] = useState([])
  const [providers, setProviders] = useState([])
  const [biologicalSexDescriptors, setBiologicalSexDescriptors] = useState([])
  const [
    alternativesToInsiteCareDescriptors,
    setAlternativesToInsiteCareDescriptors
  ] = useState([])
  const [appointmentTypes, setAppointmentTypes] = useState([])
  const [referralReasonDescriptors, setReferralReasonDescriptors] = useState([])
  const [studentTypeDescriptors, setStudentTypeDescriptors] = useState([])
  const [reasonForCareDescriptors, setReasonForCareDescriptors] = useState([])

  if (!payers?.length) getPayers.then(setPayers)
  if (!statusLabels?.length) getStatusLabels.then(setStatusLabels)
  if (!providers?.length) getProviders.then(setProviders)
  if (!biologicalSexDescriptors?.length)
    getBiologicalSexDescriptors.then(setBiologicalSexDescriptors)
  if (!alternativesToInsiteCareDescriptors?.length)
    getAlternativesToInsiteCareDescriptors.then(
      setAlternativesToInsiteCareDescriptors
    )
  if (!appointmentTypes?.length) getAppointmentTypes.then(setAppointmentTypes)
  if (!referralReasonDescriptors?.length)
    getReferralReasonDescriptors.then(setReferralReasonDescriptors)
  if (!studentTypeDescriptors?.length)
    getStudentTypeDescriptors.then(setStudentTypeDescriptors)
  if (!reasonForCareDescriptors?.length)
    getReasonForCareDescriptors.then(setReasonForCareDescriptors)

  const formatDate = (date) => {
    if (!date) return null
    const dateToFormat = new Date(date)
    return (
      dateToFormat.getFullYear() +
      '-' +
      ('0' + (dateToFormat.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + dateToFormat.getDate()).slice(-2)
    )
  }

  // const [schema, setSchema] = useState({})

  const patientQuery = useQuery({
    queryKey: ['patient'],
    queryFn: async function () {
      return await fetchPatient(patientId)
    },
    onSuccess: async (data) => {
      setOrg({ id: data?.managingOrgId })
      setDraftPatient(data)
      setTimeout(orgQuery.refetch, 1000)
    },

    select: (response) => {
      return {
        ...response?.data,
        createdAt: formatDate(response?.data?.createdAtString)
      }
    }
  })

  const orgQuery = useQuery({
    queryKey: ['org'],
    queryFn: async function () {
      if (!org) return null
      return await fetchOrg(org.id)
    },
    onSuccess: (data) => {
      if (data?.data) setOrg(data.data)
    }
  })
  //const org =

  const patient = patientQuery.data

  const patientUpdate = useMutation({
    mutationKey: ['updatePatient'],
    mutationFn: async function (patient) {
      return await updatePatient(patient)
    },
    onSuccess: (data) => {
      setNotification({
        type: 'success',
        heading: 'Success',
        message: 'Patient Updated'
      })
    },
    onError: (error) => {
      setNotification({
        type: 'error',
        heading: 'Error',
        message: error.message
      })
    },
    onSettled: (data) => {
      patientQuery.refetch()
    }
  })

  // const patient = useMemo(() => {return patients.find(p => p.id === patientId)}, [patients,  patientId])

  const isPageChanged = useMemo(() => {
    return !_.isEqual(patient, draftPatient)
  }, [patient, draftPatient])

  const setSelectedStatusLabel = (e) => {
    setDraftPatient({
      ...draftPatient,
      ...{
        status: statusLabels.find((status) => {
          return status.label === e
        })
      }
    })
  }

  const setPatientFirstName = (e) => {
    setDraftPatient({ ...draftPatient, ...{ firstName: e.target.value } })
  }

  const setPatientLastName = (e) => {
    setDraftPatient({ ...draftPatient, ...{ lastName: e.target.value } })
  }

  const setSelectedPayer = (e) => {
    setDraftPatient({
      ...draftPatient,
      ...{
        payer: payers.find((payer) => {
          return payer.label === e
        })
      }
    })
  }

  const setStudentType = (e) => {
    setDraftPatient({
      ...draftPatient,
      ...{
        studentType: studentTypeDescriptors.find((status) => {
          return status.label === e
        })
      }
    })
  }

  const setAppointmentType = (e) => {
    setDraftPatient({
      ...draftPatient,
      ...{
        appointmentType: appointmentTypes.find((appointmentType) => {
          return appointmentType.label === e
        })
      }
    })
  }
  const setSelectedProvider = (e) => {
    setDraftPatient({
      ...draftPatient,
      ...{
        provider: providers.find((provider) => {
          return provider.name === e
        })
      }
    })
  }
  const setSelectedBiologicalSex = (e) => {
    setDraftPatient({
      ...draftPatient,
      ...{
        biologicalSex: biologicalSexDescriptors.find((status) => {
          return status.label === e
        })
      }
    })
  }

  const setAlternativeToInsiteCare = (e) => {
    setDraftPatient({
      ...draftPatient,
      ...{
        alternativeToInsiteCare: alternativesToInsiteCareDescriptors.find(
          (status) => {
            return status.label === e
          }
        )
      }
    })
  }

  const setReasonForCare = (e) => {
    setDraftPatient({
      ...draftPatient,
      ...{
        reasonForCare: reasonForCareDescriptors.find((status) => {
          return status.label === e
        })
      }
    })
  }

  const setReferralReason = (e) => {
    setDraftPatient({
      ...draftPatient,
      ...{
        referralReason: referralReasonDescriptors.find((status) => {
          return status.label === e
        })
      }
    })
  }

  const auth = useAuthUser()
  const user = {
    name: auth()?.name || 'Unkown User',
    email: auth()?.email || '',
    role: auth()?.role || 'user'
  }

  const [isAdmin, setIsAdmin] = useState({ current: false, checked: false })
  if (!isAdmin.current && !isAdmin.checked)
    setIsAdmin({ current: user.role === 'admin', checked: true })

  const handleFileDownload = async (fileName) => {
    await getFile(fileName, draftPatient.id)
      .then((res) => {
        let name = res.config.url.split('***').pop().replace('_', ' ')
        const href = URL.createObjectURL(res.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', name || 'file') //or any other extension
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      })
      .catch((err) => {
        setNotification({
          type: 'error',
          heading: 'Error',
          message: err.message
        })
      })
  }

  const handleAttachFile = async (e) => {
    if (e.preventDefault) e.preventDefault()
    const file = attachFileRef.current.files[0]
    const formData = new FormData()
    formData.append('text', auth().id)
    formData.append('file', file)
    setIsLoading(true)
    await uploadFile(formData, draftPatient.id)
      .then((res) => {
        setIsLoading(false)
        setNotification({
          type: 'success',
          heading: 'Success',
          message: 'File Uploaded'
        })

        patientQuery.refetch()
      })
      .catch((err) => {
        setIsLoading(false)
        setNotification({
          type: 'error',
          heading: 'Error',
          message: err.message
        })
      })
  }
  const handleCommentSubmit = (e) => {
    e.preventDefault()
    let draft = [...draftPatient?.activity]
    draft.push({
      person: user.name || user.firstName + ' ' + user.lastName,
      comment: e.target.comment.value,
      dateTime: new Date(Date.now())
    })

    setDraftPatient({ ...draftPatient, ...{ activity: draft } })
    return patientUpdate.mutate({
      id: draftPatient.id,
      activity: draft
    })
  }

  const handleFileDelete = (fileName) => {
    deleteFile(fileName, draftPatient.id)
      .then((res) => {
        setNotification({
          type: 'success',
          heading: 'Success',
          message: 'File Deleted'
        })
        patientQuery.refetch()
      })
      .catch((err) => {
        setNotification({
          type: 'error',
          heading: 'Error',
          message: err.message
        })
      })
  }

  const handleDetailPageSave = (e) => {
    if (e) e.preventDefault()
    const draft = { id: draftPatient.id }
    const allowedFields = [
      'id',
      'status',
      'payer',
      'biologicalSex',
      'notes',
      'activity',
      'firstName',
      'lastName',
      'birthDate',
      'provider',
      'reasonForCare',
      'alternativeToInsiteCare',
      'appointmentType',
      'studentType',
      'referralReason'
    ]

    allowedFields.forEach((field) => {
      if (
        draftPatient[field] &&
        !_.isEqual(draftPatient[field], patient[field])
      )
        draft[field] = draftPatient[field]
    })
    return patientUpdate.mutate(draft)
  }

  // (!patient) return Navigate({ to: '/' })
  if (patientQuery.isLoading)
    return (
      <>
        <div className="pt-64">
          <Spinner />
        </div>
      </>
    )
  else if (!patient || !draftPatient || patientQuery.isError) return
  ;<>
    <div>{patientQuery.error?.message}</div>
  </>
  if (patientQuery.isSuccess && patient && Object.keys(draftPatient)?.length)
    return (
      <>
        {/* <Form schema={schema} validator={validator}/> */}
        <main>
          <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {/* Referral summary */}
              <div className="lg:col-start-3 lg:row-end-1">
                <h2 className="sr-only">Summary</h2>
                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                  <div className="flex-none self-end px-6 pt-4">
                    Status:
                    {isAdmin.current ? (
                      <DetailListBox
                        data={statusLabels}
                        selected={draftPatient?.status}
                        setSelected={setSelectedStatusLabel}
                      />
                    ) : (
                      <p>{draftPatient.status.label}</p>
                    )}
                  </div>
                  <div className="flex-none self-end px-6 pt-4">
                    Provider:
                    {isAdmin.current ? (
                      <DetailListBox
                        data={providers}
                        selected={draftPatient?.provider || dummy}
                        setSelected={setSelectedProvider}
                      ></DetailListBox>
                    ) : (
                      <p>{draftPatient.provider?.name}</p>
                    )}
                  </div>
                  <dl className="flex flex-wrap">
                    <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6"></div>
                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Status Date</span>
                        <DocumentPlusIcon
                          className="h-6 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        <time dateTime="2023-01-31">
                          {draftPatient?.createdAt
                            ? 'Referral created: ' +
                              formatDate(draftPatient?.createdAt)
                            : ''}
                        </time>
                      </dd>
                    </div>{' '}
                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Status Date</span>
                        <CalendarDaysIcon
                          className="h-6 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        <time dateTime="2023-01-31">
                          {draftPatient?.activity?.at(-1)
                            ? 'Last change: ' +
                              formatDate(
                                draftPatient?.activity?.at(-1).dateTime
                              )
                            : ''}
                        </time>
                      </dd>
                    </div>
                  </dl>
                  <div className="flex-1 mt-1 border-t border-gray-900/5 px-6 py-6">
                    {draftPatient?.files?.map((file, i) => {
                      return (
                        <div
                          key={file.id || file._id || file.fileName}
                          className={
                            (i % 2
                              ? 'bg-white flex-auto'
                              : ' bg-slate-100 flex-auto') +
                            'flex-auto pt-2 hover:bg-slate-50 '
                          }
                        >
                          <div className=" flex  text-xs ">
                            <span className=" flex-shrink text-xs">
                              <DocumentTextIcon
                                key={file.fileName + '1'}
                                className=" m-1 h-5"
                              />
                            </span>
                            <span className=" flex-grow  text-xs">
                              {file.fileName
                                ? file.fileName
                                    .split('***')
                                    .pop()
                                    .replace('_', ' ')
                                : 'Error'}
                            </span>
                            <span className="flex ">
                              <button
                                onClick={() => {
                                  const fileName = file.fileName
                                  handleFileDownload(fileName)
                                }}
                                value={file.fileName}
                              >
                                <ArrowDownTrayIcon
                                  id={file.fileName}
                                  key={file.fileName + '2'}
                                  className=" m-1 h-5"
                                />
                              </button>
                            </span>
                            <span className="flex  ">
                              {isAdmin.current ? (
                                <button
                                  onClick={() => {
                                    const fileName = file.fileName
                                    handleFileDelete(fileName)
                                  }}
                                  value={file.fileName}
                                >
                                  <TrashIcon className="m-1 h-5" />
                                </button>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              {/* Detail */}
              <div className="-mx-4 lg:col-span-2 lg:row-span-2 lg:row-end-2 ">
                <div className="p-2 mb-4 flex shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg">
                  <div className="mr-4 flex-shrink-0 place-content-end">
                    <InitialsAvatar
                      name={draftPatient?.name}
                      className="h-16 w-16 border border-gray-300 bg-white text-gray-300 p-4"
                      aria-hidden="true"
                    />
                  </div>

                  <div className="w-full">
                    <h4 className="text-lg font-bold">
                      <ContentEditable
                        disabled={!isAdmin.current}
                        className=""
                        onChange={setPatientFirstName}
                        html={draftPatient?.firstName}
                      ></ContentEditable>
                      <ContentEditable
                        disabled={!isAdmin.current}
                        className=""
                        onChange={setPatientLastName}
                        html={draftPatient?.lastName}
                      ></ContentEditable>
                    </h4>
                    <div className="mt-1 flex">
                      {' '}
                      <span className="mr-4 font-semibold">Gender: </span>{' '}
                      <ContentEditable
                        disabled={!isAdmin.current}
                        html={draftPatient?.gender}
                      />
                    </div>
                    <div className="mt-1 flex align-middle">
                      {' '}
                      <div className="mr-1 font-semibold pt-2">D.O.B: </div>
                      <div className=" p-0 flex-grow w-1/2">
                        <input
                          disabled={!isAdmin.current}
                          onChange={() => null}
                          className=" cursor-default mx-auto bg-white   pr-10 text-left text-gray-900 ring-0 border-none focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 text-sm p-2 ml-4 w-1/2 "
                          type="date"
                          id="dateOfBirth"
                          name="dateOfBirth"
                          value={
                            new Date(draftPatient.birthDate).getFullYear() +
                            '-' +
                            (
                              '0' +
                              (new Date(draftPatient.birthDate).getMonth() + 1)
                            ).slice(-2) +
                            '-' +
                            (
                              '0' +
                              new Date(draftPatient.birthDate).getUTCDate()
                            ).slice(-2)
                          }
                        />
                      </div>
                      <div className="flex-shrink justify-left">
                        {draftPatient?.birthDate
                          ? ' (' +
                            (-1 *
                              new Date(
                                draftPatient.birthDate
                              ).getUTCFullYear() +
                              new Date(Date.now()).getUTCFullYear()) +
                            ' years old)'
                          : 'Unkown Date of Birth'}
                      </div>
                    </div>

                    {isAdmin.current ? (
                      <div
                        className={`mt-1 flex-grow ring-1 ring-black rounded-md p-2 ${isAdmin.current ? '' : ' invisible flex-shrink '}`}
                      >
                        <div className="mt-1 w-full">
                          <p className="text-sm">
                            The following items are only viewable by InSite
                            Admins
                          </p>
                        </div>
                        <div className="mt-1 w-full">
                          <p className="text-sm">{`Referral created at: ${patient?.createdAt}`}</p>
                        </div>
                        <div className="mt-1 w-full ring-1 rounded-md ring-black p-2">
                          <h3 className=" font-semibold">Guardian Info</h3>
                          <p className="text-sm">
                            Name: {draftPatient?.guardianName || 'none on file'}
                          </p>
                          <p className="text-sm">
                            Email:{' '}
                            {draftPatient?.guardianEmail?.email ||
                              'none on file'}
                          </p>
                          <p className="text-sm">
                            Phone:{' '}
                            {draftPatient?.guardianPhoneNumber ||
                              'none on file'}
                          </p>
                        </div>

                        <div className="mt-1 w-full">
                          <span className="mr-1 font-semibold">
                            Alternatives to Care
                          </span>
                          <DetailListBox
                            data={alternativesToInsiteCareDescriptors}
                            selected={
                              draftPatient?.alternativeToInsiteCare || dummy
                            }
                            setSelected={setAlternativeToInsiteCare}
                          ></DetailListBox>
                        </div>
                        <div className="mt-1 w-full">
                          <span className="mr-1 font-semibold">
                            Appointment Type:
                          </span>
                          <DetailListBox
                            data={appointmentTypes}
                            selected={draftPatient?.appointmentType || dummy}
                            setSelected={setAppointmentType}
                          ></DetailListBox>
                        </div>
                        <div className="mt-1 w-full">
                          <span className="mr-1 font-semibold">
                            Referral Reason:
                          </span>
                          <DetailListBox
                            data={referralReasonDescriptors}
                            selected={draftPatient?.referralReason || dummy}
                            setSelected={setReferralReason}
                          ></DetailListBox>
                        </div>
                        <div className="mt-1 w-full">
                          <span className="mr-1 font-semibold">
                            Reason for care:
                          </span>
                          <DetailListBox
                            data={reasonForCareDescriptors}
                            selected={draftPatient?.reasonForCare || dummy}
                            setSelected={setReasonForCare}
                          ></DetailListBox>
                        </div>
                        <div className="mt-1 w-full">
                          <span className="mr-1 font-semibold">
                            Student Type:
                          </span>
                          <DetailListBox
                            data={studentTypeDescriptors}
                            selected={draftPatient?.studentType || dummy}
                            setSelected={setStudentType}
                          ></DetailListBox>
                        </div>

                        <div className="mt-1 w-full">
                          <span className="mr-1 font-semibold">Insurance:</span>
                          <DetailListBox
                            data={payers}
                            selected={draftPatient?.payer}
                            setSelected={setSelectedPayer}
                          ></DetailListBox>
                        </div>
                        <div className="mt-1 w-full">
                          <span className="mr-1 font-semibold">
                            Biological Sex:
                          </span>
                          <DetailListBox
                            data={biologicalSexDescriptors}
                            selected={draftPatient?.biologicalSex}
                            setSelected={setSelectedBiologicalSex}
                          ></DetailListBox>
                        </div>
                      </div>
                    ) : (
                      <div> </div>
                    )}
                  </div>
                </div>
                <div className="p-2 mb-4 flex shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg">
                  <div className="mr-4"></div>
                  <div>
                    <h4 className="text-lg font-bold">
                      Referring Organization
                    </h4>
                    <p className="mt-1">
                      {' '}
                      <span className=" font-semibold">School: </span>{' '}
                      {draftPatient?.org?.name}
                    </p>
                    <p className="mt-1">
                      {' '}
                      <span className=" font-semibold">District: </span>{' '}
                      {org ? org.name || 'Unkown' : ''}
                    </p>
                    <p className="mt-1">
                      {' '}
                      <span className=" font-semibold">Referred By: </span>
                      {draftPatient?.referredBy?.name || 'Unkown'}
                    </p>
                  </div>
                </div>

                <div
                  className={
                    true
                      ? 'p-2 mb-4 flex shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg'
                      : ' invisible'
                  }
                >
                  <div className="mr-4"></div>
                  <div>
                    <h4 className="text-lg font-bold">Notes</h4>
                    <div className="mt-1">
                      {' '}
                      <span className="mr-1 font-semibold "></span>{' '}
                      <ContentEditable
                        html={
                          '<span class="text-md">' +
                          draftPatient?.notes?.admin +
                          '</span>'
                        }
                      />
                    </div>
                    <div
                      className={
                        isAdmin.current
                          ? 'mt-1 flex-grow ring-1 ring-black rounded-md p-2'
                          : ' invisible '
                      }
                    >
                      {' '}
                      <span className="mr-1 font-semibold">
                        The following items are only viewable by InSite Admins:{' '}
                      </span>
                      <ContentEditable
                        html={
                          '<p class="text-md">' +
                          draftPatient?.notes?.staff +
                          '</p>'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:col-start-3">
                {/* Activity feed */}
                <h2 className="text-sm font-semibold leading-6 text-gray-900">
                  Activity
                </h2>
                {draftPatient?.activity?.length ? (
                  <ul className="mt-6 space-y-6">
                    {draftPatient?.activity?.map(
                      (activityItem, activityItemIdx) =>
                        activityItem ? (
                          <li
                            key={
                              activityItem.id ||
                              activityItem._id ||
                              activityItemIdx
                            }
                            className="relative flex gap-x-4"
                          >
                            <div
                              className={classNames(
                                activityItemIdx ===
                                  draftPatient?.activity.length - 1
                                  ? 'h-6'
                                  : '-bottom-6',
                                ' absolute left-0 top-0 flex w-6 justify-center'
                              )}
                            >
                              <div className="w-px bg-gray-200" />
                            </div>
                            <InitialsAvatar
                              name={(activityItem?.person &&
                              typeof activityItem?.person === 'string'
                                ? activityItem.person
                                : 'I H'
                              )
                                .replace(/\s+/g, ' ')
                                .trim()}
                              className="relative mt-3 h-9 w-9 flex-none rounded-full bg-gray-50 pl-2 pt-2"
                            />
                            <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                              <div className="flex justify-between gap-x-4">
                                <div className="py-0.5 text-xs leading-5 text-gray-500">
                                  <span className="font-medium text-gray-900">
                                    {activityItem?.person}
                                  </span>{' '}
                                  Commented:
                                </div>
                                <time
                                  dateTime={activityItem?.dateTime}
                                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                                >
                                  {activityItem?.date}
                                </time>
                              </div>
                              <p className="text-sm leading-6 text-gray-500">
                                {activityItem?.comment}
                              </p>
                            </div>
                            <time
                              dateTime={activityItem?.dateTime}
                              className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                            >
                              {activityItem?.date}
                            </time>
                          </li>
                        ) : (
                          ''
                        )
                    )}
                  </ul>
                ) : (
                  ''
                )}

                {/* New comment form */}
                <div className="mt-6 gap-x-3 ">
                  <InitialsAvatar
                    name={user?.name.replace(/\s+/g, ' ').trim()}
                  />
                  <form
                    action="#"
                    className="relative "
                    onSubmit={handleCommentSubmit}
                  >
                    <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                      <label htmlFor="comment" className="sr-only">
                        Add your comment
                      </label>
                      <textarea
                        rows={2}
                        name="comment"
                        id="comment"
                        className="m-2 pb-4  block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Add your comment to the activity feed..."
                        defaultValue={''}
                      />
                      {/* <input type="file" ref={attachFileRef} onChange={handleAttachFile} /> */}
                    </div>
                    <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                      <div className="flex items-center space-x-5">
                        <div className="flex w-full items-center"></div>
                      </div>
                      <button
                        type="submit"
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className={
                    (isAdmin.current ? '' : ' invisible ') +
                    ' inset-x-0 bottom-0 flex justify-between mt-4 mx-auto flex-grow ring-1 ring-black rounded-md p-2 '
                  }
                >
                  <form
                    onSubmit={handleAttachFile}
                    className={isAdmin.current ? ' ' : ' invisible '}
                  >
                    <input
                      type="file"
                      name="attachedFile"
                      ref={attachFileRef}
                      className="p-2 flex rounded-lg mt-2 text-gray-400 hover:text-gray-500"
                    />
                    <button
                      type="submit"
                      className={isLoading ? ' signin-disabled ' : 'signin'}
                    >
                      Attach
                    </button>
                  </form>
                </div>
                <div
                  className={`mt-6 w-full gap-x-3  ${isAdmin.current ? '' : ' invisible'}`}
                >
                  <form onSubmit={handleDetailPageSave}>
                    <div
                      className={
                        'mt-1 flex-grow ring-1 ring-black rounded-md p-2' +
                        (isPageChanged ? ' bg-red-100' : ' invisible')
                      }
                    >
                      <div className="mt-1 w-full">
                        <p className="text-xs">
                          The following items are only viewable by InSite Admins
                        </p>
                      </div>
                      <div className="justify-items flex ">
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            setDraftPatient(patient)
                          }}
                          className="rounded-md ring-2 ring-black hover:bg-black hover:text-white m-2 p-1"
                        >
                          Cancel
                        </button>
                        <button className="rounded-md ring-2 flex-grow ring-green-500 hover:bg-green-500 hover:text-white m-2 p-1">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {notification?.message ? (
            <Notification
              type={notification.type}
              heading={notification.heading}
              message={notification.message}
              handler={setNotification}
            />
          ) : (
            ''
          )}
        </main>
      </>
    )
}

const DetailListBox = ({ data, selected, setSelected }) => {
  if (!data || !setSelected) return <div key={this}>Loading...</div>
  return (
    <Listbox key={data.id} value={selected} onChange={setSelected}>
      {({ open }) => (
        <div key={data + 'div'} className="w-full">
          <div className="relative mt-2 flex-grow">
            <Listbox.Button
              className={
                'relative w-full cursor-default rounded-md bg-' +
                selected?.statusColor +
                '-100 py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
              }
            >
              <span className="block truncate">
                {selected?.label || selected?.name || 'Unkown'}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="">
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {data.map((person) => (
                    <Listbox.Option
                      key={person.id || person._id || JSON.stringify(person)}
                      className={({ active }) =>
                        'bg-' +
                        person.statusColor +
                        '-500/75 ' +
                        classNames(
                          active
                            ? 'bg-indigo-600 text-white'
                            : 'text-gray-900 bg-' + person.statusColor + '-100',
                          'relative cursor-default select-none py-2 pl-3 pr-9 ring-0 m-1 rounded ring-' +
                            person.statusColor +
                            '-800'
                        )
                      }
                      value={person.name || person.label}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {person.label || person.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}

export default DetailPage
