import Register from './components/Register'
import Login from './components/Login'
import Home from './components/Home'
import Layout from './components/Layout'
import Missing from './components/Missing'
import Unauthorized from './components/Unauthorized'
import SettingsPage from './components/SettingsPage'
import CreateReferral from './components/CreateReferral'
import Logout from './components/Logout'
import Calendar from './components/Calendar'
import Detail from './components/Detail'
import Analytics from './components/Analytics'
import SupportPage from './components/SupportPage'
import { VerifyEmailPage } from './components/VerifyEmailPage'
import { NotificationsPage } from './components/Notifications'

import { Routes, Route, Navigate } from 'react-router-dom'
import { ForgotPassword } from './components/ForgotPassword'
import { useIsAuthenticated } from 'react-auth-kit'

//https://github.com/react-auth-kit/react-auth-kit/issues/1023
const RequireAuth = ({ loginPath, Component }) => {
  const isAuthenticated = useIsAuthenticated()
  const auth = isAuthenticated()
  return auth ? <Component /> : <Navigate to={loginPath} />
}

const App = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        {/* public routes */}
        <Route path="/:demo?/login" element={<Login />} />
        <Route path="/:demo?/register" element={<Register />} />
        <Route path="/:demo?/unauthorized" element={<Unauthorized />} />
        <Route path="/:demo?/logout" element={<Logout />} />
        <Route
          path="/:demo?/forgot-password/:token?"
          element={<ForgotPassword />}
        />
        <Route path="/verify-email/:token?" element={<VerifyEmailPage />} />
        {/* we want to protect these routes */}
        <Route
          path="/"
          element={
            <RequireAuth loginPath="/login" Component={Home}></RequireAuth>
          }
        />
        <Route
          path="/settings/:page?"
          element={
            <RequireAuth
              loginPath="/login"
              Component={SettingsPage}
            ></RequireAuth>
          }
        />
        <Route
          path="/support"
          element={
            <RequireAuth
              loginPath="/login"
              Component={SupportPage}
            ></RequireAuth>
          }
        />
        <Route
          path="/createreferral"
          element={
            <RequireAuth
              loginPath="/login"
              Component={CreateReferral}
            ></RequireAuth>
          }
        />
        <Route
          path="/calendar"
          element={
            <RequireAuth loginPath="/login" Component={Calendar}></RequireAuth>
          }
        />
        <Route
          path="/detail/:patientId"
          element={
            <RequireAuth loginPath="/login" Component={Detail}></RequireAuth>
          }
        />
        <Route
          path="/analytics"
          element={
            <RequireAuth loginPath="/login" Component={Analytics}></RequireAuth>
          }
        />

        <Route
          path="/notifications"
          element={
            <RequireAuth
              loginPath="/login"
              Component={NotificationsPage}
            ></RequireAuth>
          }
        />

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  )
}

export default App
